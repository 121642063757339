import dotize from 'dotize';
// import { first, last } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// _mock_
// import { _appAuthors, _appInstalled, _appInvoices, _appRelated } from '../../_mock';
// assets
// import { SeoIllustration } from '...assets';
// components
import Page from '...components/Page';
// import useAuth from '...hooks/useAuth';
import useCounts from '...hooks/useCounts';
// import useIdentities from '...hooks/useIdentities';
// import useResults from '...hooks/useResults';
import useSettings from '...hooks/useSettings';
import useSignals from '...hooks/useSignals';
import {
  AppAreaInstalled,
  AppCurrentDownload,
  AppNewInvoice,
  AppTopAuthors,
  AppTopInstalledCountries,
  AppTopRelated,
  AppWelcome,
  AppWidget,
  AppWidgetSummary
} from '...sections/@dashboard/general/app';

// ----------------------------------------------------------------------

// const year = date.getFullYear();
// const month = date.getMonth() + 1;
// let day = date.getDay() + 1;
// if (day < 10) {
//   day = `0${day}`;
// }

// Fill in year, month and day numbers between two dates.
// Get accurate days of each month.

// To get all required counts in one query, we need to query the path outside.
// For example, range [2022-01-25, 2022-02-01] (last 7 days) needs to query /2022.
// There is probably not a great reason to make it more complicated than this.
const getUnitsFromMoment = (m) => [m.format('YYYY'), m.format('MM'), m.format('DD')];

const getCountsRangeCommon = ([startUnits, endUnits]) => {
  const commonUnits = [];
  startUnits.forEach((unit, i) => {
    if (endUnits[i] === unit) commonUnits.push(unit);
  });
  return commonUnits;
};

// Unit is days, months, years.
const getCountsRange = (startMoment, endMoment) => [
  getUnitsFromMoment(startMoment),
  getUnitsFromMoment(endMoment)
];

const getMomentsFromDistanceBack = (num, unit) => {
  const start = moment().subtract(num, unit);
  const end = moment();
  return [start, end];
};

// REVIEW: This just pushes a range of days. To display hourly, we need to pass unit as param
// and get the range incrementing by hour.
function getDotizedDatesInRange(startMoment, endMoment) {
  const date = startMoment.toDate();
  const endDate = endMoment.toDate();
  const dates = [];
  while (date <= endDate) {
    // Match format of dotized count results from rtdb.
    dates.push(moment(date).format('YYYY.MM.DD[.total]'));
    date.setDate(date.getDate() + 1);
  }
  return dates;
}

// NOW we need to boil down the counters to a single set of key/value pairs with the number as the
// value.
// Need to know the depth to do this.
// Dot notation? Let's try.

export default function GeneralApp() {
  const [countsDistanceNum, setCountsDistanceNum] = useState(7);
  const [countsDistanceUnit, setCountsDistanceUnit] = useState('days');

  const [countsRangeCommon, dotizedDatesInRange] = useMemo(() => {
    const [startMoment, endMoment] = getMomentsFromDistanceBack(
      countsDistanceNum,
      countsDistanceUnit
    );
    const countsRange = getCountsRange(startMoment, endMoment);
    const countsRangeCommon = getCountsRangeCommon(countsRange);
    const dotizedDatesInRange = getDotizedDatesInRange(startMoment, endMoment);
    console.log('[MEMO] RANGE:', countsRange, countsRangeCommon, dotizedDatesInRange);
    return [countsRangeCommon, dotizedDatesInRange];
  }, [countsDistanceNum, countsDistanceUnit]);

  // const { user } = useAuth();
  // const [loadingAnomalies, anomalies] = useResults();
  // const identities = useIdentities();
  const [loadingSignals, signals] = useSignals();

  const [loadingResultsCounts, resultsCounts] = useCounts('results', countsRangeCommon);
  const [loadingSignalsCounts, signalsCounts] = useCounts('signals', countsRangeCommon);
  const [loadingIdentitiesCounts, identitiesCounts] = useCounts(
    'identities',
    countsRangeCommon
  );

  console.log('COUNTERS:', resultsCounts, signalsCounts);
  console.log('RANGE KEYS:', dotizedDatesInRange);

  const [dotizedIdentitiesCounts, dotizedResultsCounts, dotizedSignalsCounts] =
    useMemo(() => {
      const dotizedIdentitiesCounts = dotize.convert(identitiesCounts);
      const dotizedResultsCounts = dotize.convert(resultsCounts);
      const dotizedSignalsCounts = dotize.convert(signalsCounts);
      console.log(
        '[MEMO] DOTIZED COUNTER PATHS:',
        dotizedIdentitiesCounts,
        dotizedResultsCounts,
        dotizedSignalsCounts
      );
      return [dotizedIdentitiesCounts, dotizedResultsCounts, dotizedSignalsCounts];
    }, [identitiesCounts, resultsCounts, signalsCounts]);

  const stats = useMemo(() => {
    const o = {
      identities: {
        total: 0,
        firstHalfTotal: 0,
        lastHalfTotal: 0,
        graph: []
      },
      results: {
        total: 0,
        firstHalfTotal: 0,
        lastHalfTotal: 0,
        graph: []
      },
      signals: {
        total: 0,
        firstHalfTotal: 0,
        lastHalfTotal: 0,
        graph: []
      }
    };
    // To calculate trend, get:
    // Total of the first half, total of the second half.
    const half = Math.round(dotizedDatesInRange?.length / 2);
    dotizedDatesInRange.forEach((k, i) => {
      const dateKey = k.replace('.total', '').replaceAll('.', '-');
      const countsKey = k.replace(`${countsRangeCommon.join('.')}.`, '');
      console.log('[MEMO] date key, counts key:', dateKey, countsKey);
      const identitiesCount = dotizedIdentitiesCounts[countsKey] || 0;
      const resultsCount = dotizedResultsCounts[countsKey] || 0;
      const signalsCount = dotizedSignalsCounts[countsKey] || 0;
      o.identities.total += identitiesCount;
      o.results.total += resultsCount;
      o.signals.total += signalsCount;
      if (i < half) {
        o.identities.firstHalfTotal += identitiesCount;
        o.results.firstHalfTotal += resultsCount;
        o.signals.firstHalfTotal += signalsCount;
      } else {
        o.identities.lastHalfTotal += identitiesCount;
        o.results.lastHalfTotal += resultsCount;
        o.signals.lastHalfTotal += signalsCount;
      }
      // REVIEW: Use array of objects, etc. instead if needed.
      o.identities.graph.push([dateKey, identitiesCount]);
      o.results.graph.push([dateKey, resultsCount]);
      o.signals.graph.push([dateKey, signalsCount]);
    });
    console.log('[MEMO] stats', o);
    return o;
  }, [
    countsRangeCommon,
    dotizedDatesInRange,
    dotizedIdentitiesCounts,
    dotizedResultsCounts,
    dotizedSignalsCounts
  ]);

  console.log('stats', stats);

  const theme = useTheme();

  const { themeStretch } = useSettings();

  return (
    <Page title="Project: Overview">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={12}>
            <AppWelcome
              // title={`Welcome back! \n ${user?.displayName}`}
              title="Welcome back!"
              description="Get started with DFend in minutes."
              img={
                <SeoIllustration
                  sx={{
                    p: 3,
                    width: 360,
                    margin: { xs: 'auto', md: 'inherit' }
                  }}
                />
              }
              action={
                <>
                  <Button variant="contained">Connect your app, website, or Google Workspace</Button>
                </>
              }
            />
          </Grid> */}

          {/* <Grid item xs={12} md={4}>
            <AppFeatured list={_appFeatured} />
          </Grid> */}

          <Grid item xs={12} md={12}>
            <Typography variant="h4">Recent</Typography>
            <Typography variant="subtitle2">Last 7 Days</Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Signals"
              percent={stats.signals.lastHalfTotal - stats.signals.firstHalfTotal}
              total={stats.signals.total}
              chartColor={theme.palette.primary.main}
              chartData={stats.signals.graph.map((item) => item?.[1] || 0)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Alerts"
              percent={stats.results.lastHalfTotal - stats.results.firstHalfTotal}
              total={stats.results.total}
              chartColor={theme.palette.chart.blue[0]}
              chartData={stats.results.graph.map((item) => item?.[1] || 0)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Identities"
              percent={stats.identities.lastHalfTotal - stats.identities.firstHalfTotal}
              total={stats.results.total}
              chartColor={theme.palette.chart.red[0]}
              chartData={stats.identities.graph.map((item) => item?.[1] || 0)}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppCurrentDownload
              title="Connections"
              chartColors={[
                theme.palette.primary.lighter,
                theme.palette.primary.light,
                theme.palette.primary.main,
                theme.palette.primary.dark
              ]}
              chartData={signals.reduce(
                (prev, cur) => {
                  if (cur.data.source === 'App') {
                    prev[0].value++;
                  }
                  if (cur.data.source === 'Browser') {
                    prev[1].value++;
                  }
                  if (cur.data.source === 'Server') {
                    prev[2].value++;
                  }
                  if (['GooglePush', 'GoogleFetch'].includes(cur.data.source)) {
                    prev[3].value++;
                  }
                  if (['MSFetch', 'MSPush', 'MS Entra ID'].includes(cur.data.source)) {
                    prev[4].value++;
                  }
                  if (!cur.data.source || cur.data.source === 'Unknown') {
                    prev[5].value++;
                  }
                  console.log('prev', JSON.stringify(prev));
                  console.log('cur.data.source', cur);
                  return prev;
                },
                [
                  { label: 'App', value: 0 },
                  { label: 'Browser', value: 0 },
                  { label: 'Server', value: 0 },
                  { label: 'Google Workspace', value: 0 },
                  { label: 'Microsoft Entra ID', value: 0 },
                  { label: 'Unknown', value: 0 }
                ]
              )}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppAreaInstalled
              title="Activity"
              subheader="(+43%) than last year"
              chartLabels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']}
              chartData={[
                {
                  year: '2019',
                  data: [
                    { name: 'Signals', data: [10, 41, 35, 51, 49, 62, 69, 91, 148] },
                    { name: 'Anomalies', data: [10, 34, 13, 56, 77, 88, 99, 77, 45] }
                  ]
                },
                {
                  year: '2020',
                  data: [
                    { name: 'Signals', data: [148, 91, 69, 62, 49, 51, 35, 41, 10] },
                    { name: 'Anomalies', data: [45, 77, 99, 88, 77, 56, 13, 34, 10] }
                  ]
                }
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} lg={8}>
            <AppNewInvoice
              title="Signals"
              tableData={_appInvoices}
              tableLabels={[
                { id: 'id', label: 'Signal ID' },
                { id: 'category', label: 'IP' },
                { id: 'price', label: 'Location' },
                { id: 'status', label: 'Anomalies' },
                { id: '' }
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTopRelated title="Top Related Applications" list={_appRelated} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTopInstalledCountries title="Top Installed Countries" list={_appInstalled} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTopAuthors title="Top Authors" list={_appAuthors} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Stack spacing={3}>
              <AppWidget title="Conversion" total={38566} icon={'eva:person-fill'} chartData={48} />
              <AppWidget
                title="Applications"
                total={55566}
                icon={'eva:email-fill'}
                color="warning"
                chartData={75}
              />
            </Stack>
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
