import { getAuth, isSignInWithEmailLink } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import LoadingScreen from '...components/LoadingScreen';
import Page from '...components/Page';
import { FormProvider, RHFTextField } from '...components/hook-form';
import { signIn } from '...contexts/Auth/providers/magicLink';
import useAuth from '...hooks/useAuth';
import LogoOnlyLayout from '...layouts/LogoOnlyLayout';
import { app } from '...modules/firebase/web';
import { PATH_AUTH } from '...routes/paths';

const auth = getAuth(app);

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

function EmailForm({ onEmail }) {
  const EmailSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required')
  });

  const defaultValues = {
    email: ''
  };

  const methods = useForm({
    resolver: yupResolver(EmailSchema),
    defaultValues
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async (data) => {
    onEmail(data.email);
  };

  return (
    <Box>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <>
            <RHFTextField name="email" label="Email address" />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Continue
            </LoadingButton>
          </>
        </Stack>
      </FormProvider>
    </Box>
  );
}

export default function MagicLink() {
  const { register, isInitialized, isAuthenticated } = useAuth();
  const [search] = useSearchParams();
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  console.log('HAVE EMAIL', email);

  useEffect(() => {
    if (!isInitialized) return;
    if (isAuthenticated) return;
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let e = window.localStorage.getItem('magicLinkEmail');
      if (!e) {
        setLoading(false);
      } else {
        setEmail(e);
        setLoading(false);
      }
    }
  }, [isInitialized, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && navigate) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (!(register && search)) return;
    if (!email) return;
    // Name and company.
    const n = search.get('n');
    const c = search.get('c');
    console.log('QUERY PARAMS?', { n, c });
    (async () => {
      try {
        if (n && c) {
          await register(signIn(email, { n, c }));
        } else {
          await signIn(email);
        }
      } catch (e) {
        console.log('ERROR', e);
        setError(e);
      }
    })();
  }, [register, search, email]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Page title="Magic Link">
      <LogoOnlyLayout />

      <Container>
        <ContentStyle sx={{ textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            Magic Link
          </Typography>

          {email ? (
            <>
              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Please give us a moment while we log you in.
              </Typography>
              {!!error && (
                <Alert severity="error">
                  Error. You may need to log in with a new link.
                </Alert>
              )}
            </>
          ) : (
            <>
              <Typography sx={{ mb: 3 }}>
                Please enter your email address to confirm
              </Typography>
              <EmailForm onEmail={(email) => setEmail(email)} />
            </>
          )}

          <Button
            fullWidth
            size="large"
            component={RouterLink}
            to={PATH_AUTH.login}
            sx={{ mt: 1 }}
          >
            Back
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
