export default {
  apiKey: 'AIzaSyBGSDEwIa4jj1vbkYvvTQUxVjLUa0d-tPk',
  authDomain: 'login.dfend.app',
  databaseURL: 'https://dfendpoc-default-rtdb.firebaseio.com',
  projectId: 'dfendpoc',
  storageBucket: 'dfendpoc.appspot.com',
  messagingSenderId: '244180298306',
  appId: '1:244180298306:web:d16bab6fe083704bfb5bdb',
  measurementId: 'G-XZH4M3W81Z'
};
