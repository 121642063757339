import Iconify from '../../../components/Iconify';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item')
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'dashboard',
    items: [
      { title: 'overview', path: PATH_DASHBOARD.project.overview, icon: ICONS.dashboard },
      {
        title: 'Insights',
        path: PATH_DASHBOARD.project.insights,
        icon: ICONS.analytics,
        children: [
          { title: 'signals', path: PATH_DASHBOARD.project.signals },
          { title: 'alerts', path: PATH_DASHBOARD.project.alerts },
          { title: 'identities', path: PATH_DASHBOARD.project.identities }
        ]
      },
      {
        title: 'Setup',
        path: PATH_DASHBOARD.project.setup,
        icon: ICONS.kanban,
        children: [
          { title: 'Keys', path: PATH_DASHBOARD.project.apiKeys },
          // { title: 'SDK', path: PATH_DASHBOARD.project.sdk },
          { title: 'connections', path: PATH_DASHBOARD.project.connections },
          { title: 'policies', path: PATH_DASHBOARD.project.policies }
        ]
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'manage',
    items: [
      { title: 'users', path: PATH_DASHBOARD.manage.users, icon: ICONS.user },
      { title: 'billing', path: PATH_DASHBOARD.manage.billing, icon: ICONS.invoice }
    ]
  }
];

export default navConfig;
