import { useState } from 'react';

import { Box, Button, Container, Divider, Grid, Modal, Typography } from '@mui/material';

import Page from '...components/Page';
import useAuth from '...dashboard/hooks/useAuth';
import useSettings from '...hooks/useSettings';
import IntegrationGoogleWorkspace from '...sections/@dashboard/integrations/IntegrationGoogleWorkspace';
import IntegrationMSEntra from '...sections/@dashboard/integrations/IntegrationMSEntra';
import IntegrationCard from '...sections/@dashboard/setup/IntegrationCard';

const title = 'Connections';

export default function Integrations() {
  const { themeStretch } = useSettings();
  const { integrations } = useAuth();

  const [activeIntegration, setActiveIntegration] = useState(null);

  //TODOcwf these don't do anything? always logs an emty array
  const GoogleFetchIntegration =
    integrations &&
    integrations.find((integration) => integration?.type === 'GoogleFetch');

  const MSFetchIntegration =
    integrations && integrations.find((integration) => integration?.type === 'MSFetch');

  console.log('INTEGRATIONS IN PAGE:', integrations);

  const [open, setOpen] = useState(false);
  const handleOpen = (activeIntegration) => {
    setActiveIntegration(activeIntegration);
    console.log('activeIntegration:', activeIntegration);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Page title={`Dashboard | Setup | ${title}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 4, mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            Available
          </Typography>
          <p>activeIntegration: {activeIntegration}</p>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <IntegrationCard
              label="Business"
              title="Google Workspace"
              cta="Set Up"
              onClick={() => handleOpen('Google')}
              integration={GoogleFetchIntegration}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ wordSpacing: '5px' }}>
            <IntegrationCard
              label="Business"
              title="Microsoft Entra ID"
              cta="Set Up"
              onClick={() => handleOpen('MS')}
              integration={MSFetchIntegration}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 4, mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            Future
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <IntegrationCard label="Business" title="Amazon" cta="Coming Soon" disabled />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard label="Business" title="Slack" cta="Coming Soon" disabled />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard
              label="Business"
              title="Salesforce"
              cta="Coming Soon"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard label="Business" title="Twilio" cta="Coming Soon" disabled />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard label="Business" title="GitHub" cta="Coming Soon" disabled />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard
              label="Personal"
              title="Apple iCloud"
              cta="Coming Soon"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard
              label="Personal"
              title="Dropbox"
              cta="Coming Soon"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard
              label="Personal"
              title="Binance"
              cta="Coming Soon"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard
              label="Personal"
              title="Coinbase"
              cta="Coming Soon"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard label="Personal" title="PayPal" cta="Coming Soon" disabled />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard
              label="Personal"
              title="Equifax"
              cta="Coming Soon"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard label="Personal" title="Chime" cta="Coming Soon" disabled />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard label="Personal" title="Chase" cta="Coming Soon" disabled />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard
              label="Personal"
              title="Wells Fargo"
              cta="Coming Soon"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard
              label="Personal"
              title="Twitter"
              cta="Coming Soon"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard
              label="Personal"
              title="Instagram"
              cta="Coming Soon"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard label="Personal" title="TikTok" cta="Coming Soon" disabled />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard label="Hardware" title="Cisco" cta="Coming Soon" disabled />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard
              label="Hardware"
              title="NETGEAR"
              cta="Coming Soon"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <IntegrationCard
              label="Hardware"
              title="Linksys"
              cta="Coming Soon"
              disabled
            />
          </Grid>
        </Grid>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          background: 'rgb(22,28,36)'
        }}
      >
        <>
          <Button onClick={handleClose} sx={{ position: 'absolute', top: 20, right: 20 }}>
            <Typography variant="subtitle2">close</Typography>
          </Button>
          <Container
            sx={{
              overflow: 'auto',
              background: 'rgb(22,28,36)',
              height: '100%'
            }}
            maxWidth={false}
          >
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              direction="column"
              spacing={5}
            >
              <p>activeIntegration: {activeIntegration}</p>
              {(() => {
                switch (activeIntegration) {
                  case 'Google':
                    return <IntegrationGoogleWorkspace handleClose={handleClose} />;
                  case 'MS':
                    return <IntegrationMSEntra handleClose={handleClose} />;
                  default:
                    return <div>catch all</div>;
                }
              })()}

              {/* <IntegrationGoogleWorkspace handleClose={handleClose} />
              <IntegrationMSEntra handleClose={handleClose} /> */}
            </Grid>
          </Container>
        </>
      </Modal>
    </Page>
  );
}
