import { getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import useAuth from './useAuth';
import identities from '...data/identities/collections/identities';

// import { identityWithIdSchema } from '...data/identities/schemas/identity';

const cache = {};

const pageSize = 25;
export default function useIdentities(page = 1) {
  const { account, isInitialized, isAuthenticated } = useAuth();

  const initData = account?.id ? (cache[account.id] || []).slice(0, page * pageSize) : [];

  const [data, setData] = useState(initData);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (!isInitialized || !isAuthenticated) return;
    if (isAuthenticated && !account?.id) {
      return;
    }
    if (!account?.id) {
      setData([]);
      setLoading(false);
      return;
    }
    (async () => {
      setLoading(true);
      const q = query(identities, where('account', '==', account?.id), orderBy('_m.c', 'desc'), limit((page || 1) * pageSize));
      const snapshot = await getDocs(q);
      const d = [];
      snapshot.forEach(doc => d.push({ id: doc.id, ...doc.data() }));
      setData(d);
      cache[account.id] = d;
      setLoading(false);
    })();
  }, [account?.id, page, isInitialized, isAuthenticated]);

  return [loading, data];
}
