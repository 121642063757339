import { onSnapshot, orderBy, query, setDoc, where } from 'firebase/firestore';
import { isFinite, uniqBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Iconify from '...components/Iconify';
import Label from '...components/Label';
import Page from '...components/Page';
import useIdentity from '...hooks/useIdentity';
import useSettings from '...hooks/useSettings';
import useSignal from '...hooks/useSignal';
import { ContactMap } from '...sections/contact';

// import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const title = 'Signal';

const SectionContainer = ({ title, children }) => (
  <Stack spacing={2} sx={{ mt: 4, mb: 4 }}>
    {!!title && (
      <Typography variant="h6" sx={{ color: 'text.primary' }}>
        {title}
      </Typography>
    )}
    {children}
  </Stack>
);

const Section = ({ title, caption, body, children }) => (
  <Stack spacing={1}>
    {!!title && (
      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {title}
      </Typography>
    )}
    {!!caption && (
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        {caption}
      </Typography>
    )}
    {!!body && (
      <Typography variant="body2" sx={{ color: 'text.primary' }}>
        {body}
      </Typography>
    )}
    {children}
  </Stack>
);

// ---------------------------------------------

export default function InsightsSignal() {
  let { id } = useParams();

  // const { user } = useAuth();

  const theme = useTheme();

  const { themeStretch } = useSettings();

  const [loadingSignal, s] = useSignal(id);
  const [loadingIdentity, i] = useIdentity(s?.identity);

  console.log('SIGNAL?', s);
  console.log('IDENTITY?', i);

  // ---------------------------------------------

  const ipdata = s?.data?.enrichment?.ipdata;

  // Source.
  const time = moment(s?.data?.time?.toDate()).format('YYYY/MM/DD [at] h:mm:ss a');
  const receipt = moment(s?._m?.c?.toDate()).format('YYYY/MM/DD [at] h:mm:ss a');
  const source = s?.data?.source;
  const type = s?.data?.type;
  const trusted = s?.trusted;

  // Location.
  const city = ipdata?.city;
  const region = ipdata?.region;
  const regionType = ipdata?.region_type;
  const country = ipdata?.country_name;
  const flag = ipdata?.emoji_flag;
  const latitude = ipdata?.latitude;
  const longitude = ipdata?.longitude;

  // Network.
  const userAgent = s?.data?.userAgent;
  const ip = s?.data?.ip;
  const companyName = ipdata?.company?.name;
  // const companyNetwork = ipdata?.company?.network;
  const isVpn = ipdata?.threat?.is_vpn;
  // const isAnon = ipdata?.threat?.is_anonymous;
  // const vpnScore = ipdata?.threat?.scores?.vpn_score;

  // Anomalies.
  const anomalies = uniqBy(s?.immediateResults, 'type');
  let risky = false;
  anomalies?.forEach((anomaly) => {
    if (anomaly.level !== 'INFO') {
      risky = true;
    }
  });

  // Map.
  let coords = [];
  if (isFinite(latitude) && isFinite(longitude)) {
    coords.push({ latlng: [latitude, longitude], source, type, anomalies, time });
  }

  // Identity.
  const identity = s?.identity;
  const ide = i?.identifiers;
  const names = ide?.names?.join(', ');
  const emails = ide?.emails?.join(', ');
  const phones = ide?.phones?.join(', ');
  const userIds = ide?.userIds?.join(', ');

  // ---------------------------------------------

  return (
    <Page title={`Dashboard | Insights | ${title}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={risky ? 8 : 12}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          {risky && (
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
                //pr: 1.5
              }}
            >
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color="error"
              >
                HIGH RISK
              </Label>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <div>
              <Typography variant="h5" style={{ display: 'inline' }}>
                Risk Score
              </Typography>
              <Typography style={{ display: 'inline' }}>Current</Typography>
            </div>
            <Divider />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Divider />
            <SectionContainer>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Section title="Occurred" body={time} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Section title="Received" body={receipt} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Section title="Source" body={source} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Section title="Type" body={type} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Section title="IP" body={ip} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Section title="Company" body={companyName} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Section title="Recognized VPN" body={`${isVpn}`} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Section title="User Agent" body={`${userAgent || 'none'}`} />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Section title="Trusted" body={`${trusted}`} />
                </Grid> */}
              </Grid>
            </SectionContainer>
            <Divider />
            <SectionContainer title="Alerts">
              <Grid container>
                {!anomalies?.length && (
                  <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>
                    NONE
                  </Label>
                )}
                {anomalies?.map((result) => (
                  <Grid key={result.id} item xs={12} md={6}>
                    <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color="error"
                    >
                      {result.type.replaceAll('_', ' ')}
                    </Label>

                    <Link to={`/dashboard/insights/alerts/${result.id}`}>
                      <IconButton sx={{ ml: 0.5 }}>
                        <Iconify
                          icon={'eva:external-link-fill'}
                          sx={{ width: 18, height: 18 }}
                        />
                      </IconButton>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </SectionContainer>
            <Divider />
            <SectionContainer
              title={
                <>
                  <span>Identity</span>
                  <Link to={`/dashboard/insights/identities/${identity}`}>
                    <IconButton sx={{ ml: 0.5 }}>
                      <Iconify
                        icon={'eva:external-link-fill'}
                        sx={{ width: 18, height: 18 }}
                      />
                    </IconButton>
                  </Link>
                </>
              }
            >
              {!!names && <Section title="Names" body={names} />}
              {!!emails && <Section title="Emails" body={`${emails}`} />}
              {!!phones && <Section title="Phone Numbers" body={`${phones}`} />}
              {!!userIds && <Section title="User IDs" body={`${userIds}`} />}
            </SectionContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              {!!s && (
                <ContactMap
                  contacts={coords}
                  initialViewState={
                    coords.length ? { latitude, longitude, zoom: 7 } : null
                  }
                />
              )}
            </Box>
            <SectionContainer title="Location">
              {coords.length ? (
                <Stack spacing={3}>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Section title="City" body={city} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Section title={regionType} body={region} />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Section title="Country" body={`${country} ${flag}`} />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Section title="Latitude" body={latitude} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Section title="Longitude" body={longitude} />
                    </Grid>
                  </Grid>
                </Stack>
              ) : (
                <Grid container>
                  <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>
                    NONE
                  </Label>
                </Grid>
              )}
            </SectionContainer>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
