import { addDoc, getDocs, onSnapshot } from 'firebase/firestore';
import moment from 'moment';
import numeral from 'numeral';
import { useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';

import { EcommerceSalesOverview } from '../../sections/@dashboard/general/e-commerce';
import Label from '...components/Label';
import Page from '...components/Page';
import {
  getCheckoutSessions,
  getPricesQuery,
  getProductsQuery
} from '...data/billing/collections';
import useAuth from '...hooks/useAuth';
import useCounts from '...hooks/useCounts';
import useSettings from '...hooks/useSettings';
import getHttpsCallable from '...utils/getHttpsCallable';

const createPortalLink = getHttpsCallable(
  'ext-firestore-stripe-payments-createPortalLink'
);

const standardPricing = '0.003';

const getProductName = (p) =>
  `${p?.name} - ${numeral(p.tiers?.[0]?.up_to)
    .format('0a')
    .toUpperCase()} included requests/${p?.interval}, then $${standardPricing}/request`;

function SectionContainer({ children }) {
  return <div style={{ flex: 1 }}>{children}</div>;
}

function SectionHeader({ title, status, children }) {
  const statusColor = /active/i.test(status) ? 'success' : 'error';
  return (
    <Stack
      sx={{ fontSize: 22 }}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <Typography variant="h6">
          {title}
          {/plan/i.test(title) ? '' : ' Plan'}{' '}
          {status && (
            <Label color={statusColor} sx={{ ml: 1 }}>
              {status}
            </Label>
          )}
        </Typography>
      </Box>
      {children}
    </Stack>
  );
}

function SectionBlock({ children, style = {}, ...props }) {
  return (
    <Box sx={{ mt: 3, ...style }} {...props}>
      {children}
    </Box>
  );
}

function ButtonBlock({ children, style = {}, ...props }) {
  return (
    <Box sx={{ mt: 2, mb: 0, ...style }} {...props}>
      {children}
    </Box>
  );
}

function Subscription({ s, isOwner, ownerEmail }) {
  const [loadingEditSubscription, setLoadingEditSubscription] = useState(false);

  const [loadingCounts, count] = useCounts(
    'requests',
    [moment().format('YYYY'), moment().format('MM')],
    true
  );
  console.log('requests', count);
  async function editSubscription() {
    if (loadingEditSubscription) return;
    setLoadingEditSubscription(true);
    try {
      const { data } = await createPortalLink({
        returnUrl: window.location.href
        //locale: "auto", // Optional, defaults to "auto"
        //configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
      });
      window.location.assign(data.url);
    } catch (e) {
      alert(`An error occured: ${e.message}`);
      setLoadingEditSubscription(false);
    }
  }

  const item = s?.items?.[0];
  const name = item?.price?.product?.name;

  const status = s.status === 'active' ? 'Active' : 'Inactive';
  const periodEndMoment = moment(s.current_period_end.toDate());
  console.log('Subscription item/name', item, name, status);
  return (
    <SectionContainer>
      <SectionHeader title={name || 'Subscription'} status={status}>
        {isOwner ? (
          <LoadingButton
            variant="contained"
            onClick={editSubscription}
            loading={loadingEditSubscription}
          >
            Change Plan
          </LoadingButton>
        ) : (
          <Typography variant="body2">
            Managed by <strong>{ownerEmail}</strong>
          </Typography>
        )}
      </SectionHeader>
      <SectionBlock>
        <strong>Current period</strong>
        <div style={{ marginTop: 3, fontSize: 15 }}>
          ${((item?.price?.unit_amount || 0) / 100).toFixed(2)} <small>USD</small> will be
          charged on {periodEndMoment.format('MMM Do, YYYY')}.
        </div>
      </SectionBlock>

      <SectionBlock>
        <EcommerceSalesOverview
          title="Usage"
          data={[
            {
              label: 'Requests (Included)',
              amount: `${count || 0} / 1000`,
              value: (count || 0) / 1000 // Percentage used
            },
            {
              label: 'Additional Requests ($0.003/Request)',
              amount: '0',
              value: 0 // Percentage used
            }
          ]}
        />
      </SectionBlock>
      {s.cancel_at && (
        <p style={{ marginTop: 3 }}>
          <strong>
            Subscription will cancel on{' '}
            {moment(s.cancel_at.toDate()).format('MMMM Do YYYY')}
            &nbsp;({periodEndMoment.fromNow()}).
          </strong>
        </p>
      )}
    </SectionContainer>
  );
}

export function Payments() {
  const { user, account } = useAuth();
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [loadingPlans, setLoadingPlans] = useState(true);

  const subscriptions = [];
  if (account?.billing?.subscription) subscriptions.push(account.billing.subscription);

  useEffect(() => {
    if (!user?.id) return;

    (async () => {
      const querySnapshot = await getDocs(getProductsQuery());

      const stripePlans = [];
      await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const prod = doc.data();
          console.log('Stripe product:', prod);
          const priceSnap = await getDocs(getPricesQuery(doc.id));

          priceSnap.docs.forEach((subDoc) => {
            console.log('Stripe product price:', subDoc.data());
            const price = subDoc.data();
            stripePlans.push({
              id: subDoc.id,
              ...price,
              name: prod.name,
              description: prod.description
            });
          });
        })
      );
      const basePlan = stripePlans.find((plan) => /develop/i.test(plan.name));
      const stripePlansNoCustom = stripePlans.filter(
        (plan) => !/custom/i.test(plan.name)
      );
      setPlans(stripePlansNoCustom);
      setSelectedPlan(basePlan);
      setLoadingPlans(false);
    })();
  }, [user?.id]);

  async function checkout() {
    if (!user?.id) {
      alert('Error: Missing user Id');
      return;
    }
    if (loadingCheckout) return;

    setLoadingCheckout(true);
    const docRef = await addDoc(getCheckoutSessions(user?.id), {
      price: selectedPlan.id,
      // eslint-disable-next-line camelcase
      success_url: window.location.origin,
      // eslint-disable-next-line camelcase
      cancel_url: window.location.origin,
      metadata: {
        accountId: account.id
      }
    });

    // Wait for the CheckoutSession to get attached by the extension
    const unsubscribe = onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
        unsubscribe();
        setLoadingCheckout(false);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  }

  console.log('Plans', plans);
  console.log('Subscriptions', subscriptions);
  console.log('Selected plan', selectedPlan);

  return (
    <div style={{ minWidth: 900 }}>
      {user?.role !== 'admin' ? (
        <Typography>Must be admin role to manage billing.</Typography>
      ) : loadingPlans ? (
        <Box>Loading</Box>
      ) : subscriptions && subscriptions.length ? (
        <Subscription
          s={subscriptions[0]}
          isOwner={account.billing.ownerId === user.id}
          ownerEmail={account.billing.ownerEmail}
        />
      ) : (
        <SectionContainer>
          <SectionHeader title="Choose Plan" />
          <SectionBlock>
            <Select value={selectedPlan} onChange={setSelectedPlan}>
              {plans.map((plan, i) => (
                <MenuItem key={i} value={plan}>
                  {getProductName(plan)}
                </MenuItem>
              ))}
            </Select>
          </SectionBlock>
          <ButtonBlock>
            <LoadingButton
              size="large"
              variant="contained"
              onClick={checkout}
              loading={loadingCheckout}
            >
              Subscribe
            </LoadingButton>
          </ButtonBlock>
        </SectionContainer>
      )}
    </div>
  );
}

const title = 'Billing';

// Manage billing.
// ---------------------------------------------
export default function ManageBilling() {
  const { themeStretch } = useSettings();

  return (
    <Page title={`Dashboard | Manage | ${title}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 3 }}>
          <Payments />
        </Box>
      </Container>
    </Page>
  );
}
