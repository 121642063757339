import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import useAuth from './useAuth';
import { RESULTS_PATH } from '...data/results/constants';
import { app } from '...modules/firebase/web';

const firestore = getFirestore(app);

const getResult = async (id) => {
  const docRef = doc(firestore, RESULTS_PATH, id);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : undefined;
};

const cache = {};

const getDocFromCache = (accountId, id) => {
  if (accountId && id) {
    if (cache[accountId]) {
      return cache[accountId][id];
    }
  }
};

const addDocToCache = (accountId, id, doc) => {
  if (accountId && id && doc) {
    if (cache[accountId]) {
      cache[accountId][id] = doc;
      return;
    }
    cache[accountId] = {
      [id]: doc
    };
  }
};

export default function useResult(id) {
  const { account, isAuthenticated, isInitialized } = useAuth();
  const [data, setData] = useState(getDocFromCache(account?.id, id));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) return;
    if (!isInitialized || !isAuthenticated) return;
    if (isAuthenticated && !account?.id) {
      return;
    }
    (async () => {
      setLoading(true);
      const _doc = await getResult(id);
      setData(_doc);
      addDocToCache(account?.id, id, _doc);
      setLoading(false);
    })();
  }, [account?.id, id, isAuthenticated, isInitialized]);

  console.log('RESULT', data);

  return [loading, data];
}
