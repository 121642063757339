import { getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import moment from 'moment';
import { useEffect, useState } from 'react';

import useAuth from './useAuth';
import signals from '...data/signals/collections/signals';

// import { signalWithIdSchema } from '...data/signals/schemas/signal';

const cache = {};

const pageSize = 25;
export default function useSignals(page = 1) {
  const { account, isInitialized, isAuthenticated } = useAuth();

  const initData = account?.id ? (cache[account.id] || []).slice(0, page * pageSize) : [];

  const [data, setData] = useState(initData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isInitialized || !isAuthenticated) return;
    if (isAuthenticated && !account?.id) {
      return;
    }
    (async () => {
      setLoading(true);
      const q = query(signals, where('account', '==', account?.id), orderBy('_m.c', 'desc'), limit((page || 1) * pageSize));
      const snapshot = await getDocs(q);
      const d = [];
      snapshot.forEach(doc => d.push({ id: doc.id, ...doc.data() }));
      setData(d);
      cache[account.id] = d;
      setLoading(false);
    })();
  }, [isInitialized, account?.id, page, isAuthenticated]);

  console.log('SIGNALS', data.map(s => ({
    date: moment(s._m.c.toDate()).format('YYYY-MM-DD'),
    type: s.data.type,
    source: s.data.source
  })));

  return [loading, data];
}
