import { getAuth, onIdTokenChanged, signOut } from 'firebase/auth';
import {
  doc,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where
} from 'firebase/firestore';
import { node } from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';

// import accounts from '...data/accounts/collections/accounts';
import integrations from '...data/integrations/collections/integrations';
import keys from '...data/keys/collections/keys';
import members from '...data/members/collections/members';
import notifications from '...data/notifications/collections/notifications';
import subscriptions from '...data/subscriptions/collections/subscriptions';
import { app } from '...modules/firebase/web';
import getHttpsCallable from '...utils/getHttpsCallable';

// ----------------------------------------------------------------------

const auth = getAuth(app);
const firestore = getFirestore(app);
const authRegisterUser = getHttpsCallable('authRegisterUser');

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  roles: null
};

const reducer = (state, action) => {
  console.log('reducer called with:', action);
  if (action.type === 'LOGGED_OUT') {
    return {
      ...state,
      isAuthenticated: false,
      isInitialized: true,
      user: null,
      roles: null
    };
  }
  if (action.type === 'LOGGING_IN') {
    const { user, roles } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      isInitialized: false,
      user,
      roles
    };
  }
  if (action.type === 'LOGGED_IN') {
    return {
      ...state,
      isAuthenticated: true,
      isInitialized: true
      // Possibly other initial data here.
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

// TODO: Revise this based on user role.
const observeAccount = (accountId, onData) => {
  if (!accountId) {
    return;
  }
  const da = doc(firestore, 'accounts', accountId);
  const qk = query(keys, where('account', '==', accountId));
  const qm = query(members, where('entity', '==', accountId));
  const qs = query(subscriptions, where('account', '==', accountId));
  const qi = query(integrations, where('account', '==', accountId));
  const qn = query(
    notifications,
    where('account', '==', accountId),
    orderBy('_m.c', 'desc'),
    limit(25)
  );

  let _account = null;
  let _keys = [];
  let _members = [];
  let _subscriptions = [];
  let _integrations = [];
  let _notifications = [];
  // let customer = null; // TODO: Get billing stuff from Stripe Firebase Extension.

  let initAccount = false;
  let initKeys = false;
  let initMembers = false;
  let initSubscriptions = false;
  let initIntegrations = false;
  let initNotifications = false;

  const callOnData = () => {
    if (
      initAccount &&
      initKeys &&
      initMembers &&
      initSubscriptions &&
      initIntegrations &&
      initNotifications
    ) {
      // Call after they all run the first time.
      onData({
        account: _account,
        keys: _keys,
        members: _members,
        subscriptions: _subscriptions,
        integrations: _integrations,
        notifications: _notifications
      });
    }
  };

  let stops = [
    // Account doc
    onSnapshot(da, (doc) => {
      _account = { id: doc.id, ...doc.data() };
      initAccount = true;
      callOnData();
    }),
    // Keys for account
    onSnapshot(qk, (snapshot) => {
      _keys = [];
      snapshot.forEach((doc) => _keys.push({ id: doc.id, ...doc.data() }));
      initKeys = true;
      callOnData();
    }),
    // Members for account
    onSnapshot(qm, (snapshot) => {
      _members = [];
      snapshot.forEach((doc) => _members.push({ id: doc.id, ...doc.data() }));
      initMembers = true;
      callOnData();
    }),
    // Subscriptions for account
    onSnapshot(qs, (snapshot) => {
      _subscriptions = [];
      snapshot.forEach((doc) => _subscriptions.push({ id: doc.id, ...doc.data() }));
      initSubscriptions = true;
      callOnData();
    }),
    // Integrations for account
    onSnapshot(qi, (snapshot) => {
      _integrations = [];
      snapshot.forEach((doc) => _integrations.push({ id: doc.id, ...doc.data() }));
      initIntegrations = true;
      callOnData();
    }),
    // Notifications for account
    onSnapshot(qn, (snapshot) => {
      _notifications = [];
      snapshot.forEach((doc) => _notifications.push({ id: doc.id, ...doc.data() }));
      initNotifications = true;
      callOnData();
    })
  ];

  return () => {
    stops.forEach((stop) => stop());
  };
};

// const observeMembership = (uid, onData) => {
//   const qm = query(members, where('user.uid', '==', uid));
//   return onSnapshot(qm, (snapshot) => {
//     const _members = [];
//     snapshot.forEach((doc) => _members.push(doc.data()));
//     onData(_members);
//   });
// };

// const observeAccounts = (_members, onData) => {
//   const accountIds = _members.filter((m) => m.entity !== 'global').map((m) => m.entity);
//   const qa = query(accounts, where(FieldPath.documentId(), 'in', accountIds));
//   return onSnapshot(qa, (snapshot) => {
//     const _accounts = [];
//     snapshot.forEach((doc) => _accounts.push(doc.data()));
//     onData(_accounts);
//   });
// };

// ----------------------------------------------------------------------

// const path = 'users';
// console.log('watching rtdb path:', path);
// const dbRef = ref(database, path);
// onValue(dbRef, (snapshot) => {
//   console.log('rtdb snapshot', snapshot.val());
//   // if (snapshot.val()) {
//   //   console.log('id token updated...force refreshing token');
//   // }
// });

AuthProvider.propTypes = {
  children: node
};

// "Initialize" procedure requires the following:
// - User logs in. Turn OFF isInitialized, to show loading screen.
// - Data is acquired. AT LEAST: membership (must be member of one account) and account
// - If registration is in progress, DO NOT allow dispatching isAuthenticated from onAuthStateChanged.
//   Wait until the documents have been created on the server. Load initial data,
//   and then dispatch from register.
// - If registration is NOT in progress, load initial data, and dispatch from onAuthStateChanged.

let registering = false;
let lastUserId;
const initAccountData = {
  account: null,
  keys: [],
  members: [],
  subscriptions: [],
  integrations: [],
  notifications: []
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [accountData, setAccountData] = useState(initAccountData);

  // From Member doc for current active Account ()
  // const [profile, setProfile] = useState(null);

  useEffect(
    () =>
      onIdTokenChanged(auth, async (user) => {
        console.log('auth state changed', user);
        if (user) {
          const { claims } = await user.getIdTokenResult();
          console.log('User ID:', user.uid);
          console.log('User claims:', claims);
          if (!registering && user.uid !== lastUserId) {
            const roles = claims.roles || null;
            if (!(roles && Object.keys(roles).length)) {
              // User must have logged in when they should have registered.
              console.log('Creating an anonymous account...');
              try {
                await authRegisterUser({});
                user.getIdTokenResult(true);
                return;
              } catch (e) {
                console.error(e);
              }
            }
            dispatch({
              type: 'LOGGING_IN',
              payload: { user, roles }
            });
            lastUserId = user.uid;
          }
        } else {
          dispatch({
            type: 'LOGGED_OUT'
          });
          lastUserId = null;
          setAccountData(initAccountData);
        }
      }),
    [dispatch]
  );

  const accountId = Object.keys(state?.roles || {}).filter((id) => id !== 'global')[0];

  useEffect(() => {
    if (accountId) {
      console.log('Account ID', accountId);
      const stop = observeAccount(accountId, (data) => {
        setAccountData(data);
        console.log('setting account data:', data);
      });
      dispatch({
        type: 'LOGGED_IN',
        payload: {
          // I guess put other initial data in here, or not
        }
      });
      return stop;
    }
  }, [accountId]);

  const register = async (providerPromise) => {
    registering = true;
    const userCredential = await providerPromise;
    setTimeout(() => {
      registering = false;
      userCredential.user.getIdToken(true);
    }, 1000);
  };

  const logout = () => signOut(auth);

  const user = {
    id: state?.user?.uid,
    email: state?.user?.email,
    photoURL: state?.user?.photoURL,
    displayName: state?.user?.displayName,
    role: (state?.roles || {})[accountId],
    phoneNumber: state?.user?.phoneNumber
  };

  const value = {
    ...state,
    ...accountData,
    method: 'firebase',
    user,
    register,
    logout
  };

  console.log('AuthProvider value:', value);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider };
