import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  Typography
} from '@mui/material';

import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import * as google from '...contexts/Auth/providers/google';
import * as magicLink from '...contexts/Auth/providers/magicLink';

// ----------------------------------------------------------------------

function InfoForm({ onContinue }) {
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    company: Yup.string().required('Company name is required')
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    company: ''
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues
  });

  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    const displayName = `${data.firstName} ${data.lastName}`;
    onContinue({
      displayName,
      company: data.company,
      email: data.email
    });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name" />
          <RHFTextField name="lastName" label="Last name" />
        </Stack>
        <RHFTextField name="company" label="Account name" />
        <Button fullWidth size="large" variant="contained" type="submit">
          Continue
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default function RegisterForm() {
  const { register } = useAuth();

  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [info, setInfo] = useState(null);
  const [loadingGoogle, setLoadingGoogle] = useState(false);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required')
  });

  const defaultValues = {
    email: ''
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = methods;

  const onSubmit = async (data, type) => {
    if (isSubmitting || loadingGoogle) return;
    try {
      if (type !== 'google') {
        // Don't wrap in `register` because this happens on magic link page.
        await magicLink.register({
          ...info,
          email: data.email
        });
        navigate('/auth/magic-link-sent');
      } else {
        setLoadingGoogle(true);
        await register(
          google.register({
            ...info
          })
        );
      }
    } catch (error) {
      setLoadingGoogle(false);
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  const onContinue = (_info) => {
    setInfo(_info);
    setStep(1);
  };

  const onBack = () => {
    setStep(0);
  };

  return (
    <Box>
      {step === 0 && <InfoForm onContinue={onContinue} />}
      {step === 1 && (
        <>
          <Box sx={{ mb: 4 }} flexDirection="row">
            <IconButton onClick={onBack}>
              <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
            </IconButton>
            <Typography variant="subtitle1" display="inline">
              How would you prefer to log in?
            </Typography>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
          </Box>
          <LoadingButton
            fullWidth
            size="large"
            color="inherit"
            variant="outlined"
            onClick={() => onSubmit(null, 'google')}
            loading={loadingGoogle}
          >
            <Iconify icon={'eva:google-fill'} color="#DF3E30" width={24} height={24} />
          </LoadingButton>
          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              OR
            </Typography>
          </Divider>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <>
                <RHFTextField name="email" label="Email address" />
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Send Magic Link
                </LoadingButton>
              </>
            </Stack>
          </FormProvider>
        </>
      )}
    </Box>
  );
}
