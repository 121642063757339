import { sentenceCase } from 'change-case';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
// @mui
import { useTheme } from '@mui/material/styles';

import Iconify from '../../../components/Iconify';
// components
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import { TableHeadCustom, TableMoreMenu } from '../../../components/table';
// utils
import { fCurrency } from '../../../utils/formatNumber';
import normalizeDate from '...utils/normalizeDate';

// import { uniq } from 'lodash';

// ----------------------------------------------------------------------

AppNewInvoice.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  tableData: PropTypes.array.isRequired,
  tableLabels: PropTypes.array.isRequired
};

export default function AppNewInvoice({
  title,
  subheader,
  tableData,
  tableLabels,
  canLoadMore,
  onLoadMore,
  ...other
}) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData?.map((row) => (
                <AppNewInvoiceRow key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2 }}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Box>
            <Typography variant="body2">{subheader}</Typography>
          </Box>
          <Box>
            {canLoadMore ? (
              <Button
                size="small"
                color="inherit"
                endIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} />}
                onClick={onLoadMore}
              >
                Load More
              </Button>
            ) : null}
          </Box>
        </Stack>
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

AppNewInvoiceRow.propTypes = {
  row: PropTypes.shape({
    category: PropTypes.string,
    id: PropTypes.string,
    price: PropTypes.number,
    status: PropTypes.string
  })
};

function AppNewInvoiceRow({ row }) {
  const theme = useTheme();

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleDownload = () => {
    handleCloseMenu();
    console.log('DOWNLOAD', row.id);
  };

  const handlePrint = () => {
    handleCloseMenu();
    console.log('PRINT', row.id);
  };

  const handleShare = () => {
    handleCloseMenu();
    console.log('SHARE', row.id);
  };

  const handleDelete = () => {
    handleCloseMenu();
    console.log('DELETE', row.id);
  };

  let names =
    row.identifiers?.names?.map((s, i) => (
      <Typography key={i} variant="body2">
        {s}
      </Typography>
    )) || [];
  let emails =
    row.identifiers?.emails?.map((s, i) => (
      <Typography key={i} variant="body2">
        {s}
      </Typography>
    )) || [];
  let phones =
    row.identifiers?.phones?.map((s, i) => (
      <Typography key={i} variant="body2">
        {s}
      </Typography>
    )) || [];
  // let userIds = row.identifiers?.userIds || [];

  if (row.contactName) {
    names = [
      <Typography key="contact" variant="body2">
        {row.contactName} <Label>contact</Label>
      </Typography>,
      ...names
    ];
  }
  if (row.contactEmail) {
    emails = [
      <Typography key="contact" variant="body2">
        {row.contactEmail} <Label>contact</Label>
      </Typography>,
      ...emails
    ];
  }
  if (row.contactPhone) {
    phones = [
      <Typography key="contact" variant="body2">
        {row.contactPhone} <Label>contact</Label>
      </Typography>,
      ...phones
    ];
  }

  console.log('IDENTITY ROW', row);

  const placeholder = <Typography sx={{ color: 'text.secondary' }}>-</Typography>;

  return (
    <TableRow>
      <TableCell>
        <Link to={`/dashboard/insights/identities/${row.id}`}>
          <IconButton>
            <Iconify icon={'eva:external-link-fill'} sx={{ width: 18, height: 18 }} />
          </IconButton>
        </Link>
      </TableCell>

      <TableCell>{names.length ? names : placeholder}</TableCell>

      <TableCell>{emails.length ? emails : placeholder}</TableCell>

      <TableCell>{phones.length ? phones : placeholder}</TableCell>

      <TableCell>
        <Typography variant="body2">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={row.optedIn ? 'success' : 'error'}
          >
            {row.optedIn ? 'yes' : 'no'}
          </Label>
        </Typography>
      </TableCell>

      <TableCell>{moment(normalizeDate(row._m?.c)).format('YY-M-D h:mm:ssa')}</TableCell>

      {/* <TableCell>
        {row.signals && row.signals.map(signal => <Typography key={signal.id}>{signal.id}</Typography>)}
      </TableCell> */}

      {/* <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem onClick={handleDownload}>
                <Iconify icon={'eva:download-fill'} />
                Download
              </MenuItem>

              <MenuItem onClick={handlePrint}>
                <Iconify icon={'eva:printer-fill'} />
                Print
              </MenuItem>

              <MenuItem onClick={handleShare}>
                <Iconify icon={'eva:share-fill'} />
                Share
              </MenuItem>

              <Divider sx={{ borderStyle: 'dashed' }} />

              <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
            </>
          }
        />
      </TableCell> */}
    </TableRow>
  );
}
