import {
  Box,
  Card,
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Label from '../../components/Label';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';

export default function ApiKeys() {
  const { themeStretch } = useSettings();
  const { keys } = useAuth();
  const prod = keys.filter(key => key.env !== 'test');
  return (
    <Page title="Dashboard | Setup | API Keys">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom>
            API Keys
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 3 }}>

          <CustomTable tableData={prod} />
        </Box>
      </Container>
    </Page>
  );
}

function CustomTable({ title, subheader, tableData, tableLabels, ...other }) {
  return (
    <Card {...other}>
      {/* <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} /> */}
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720, p: 2 }}>
          <Table>
            <TableBody>
              {tableData.map((row) => (
                <CustomRow key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

function CustomRow({ row }) {
  const theme = useTheme();

  return (
    <TableRow>
      <TableCell>

        <Typography variant="subtitle2"> {row.id} </Typography>

      </TableCell>
      <TableCell>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {row.type} key
        </Typography>

      </TableCell>
      <TableCell>
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={
            (row.env === 'live' && 'primary') ||
            (row.env === 'test' && 'warning')
          }
        >
          {row.env}
        </Label>
      </TableCell>
    </TableRow>
  );
}
