export const ID_NAMESPACE = 'sig';

export const SIGNALS_PATH = 'signals';

export const SOURCE_TYPES = {
  App: 'App',
  Browser: 'Browser',
  Server: 'Server',
  GooglePush: 'GooglePush',
  GoogleFetch: 'GoogleFetch',
  MSPush: 'MSPush',

  //TODOcwf maybe this will work?
  MSFetch: 'MSFetch',
  'MS Entra ID': 'MS Entra ID',
  MSEntraId: 'MSEntraId',
  Identity: 'Identity', // Looks like we aren't using this? Was this for opting in or approvals?
  Unknown: 'Unknown'
} as const;

export type SOURCE_TYPES_KEYS = keyof typeof SOURCE_TYPES;

export const SOURCE_TYPES_REGEX = new RegExp(Object.values(SOURCE_TYPES).join('|'));
