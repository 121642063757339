import { onSnapshot, orderBy, query, setDoc, where } from 'firebase/firestore';
import { isFinite, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';

import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';

import Page from '../../components/Page';
// import useAuth from '../../hooks/useAuth';
import useCounts from '../../hooks/useCounts';
import useSettings from '../../hooks/useSettings';
import SignalsList from '../../sections/@dashboard/insights/Signals';
import { ContactMap } from '../../sections/contact';
import Iconify from '...components/Iconify';
import useSignals from '...hooks/useSignals';

// ----------------------------------------------------------------------

const title = 'Signals';

export default function Signals() {
  // const { user } = useAuth();

  // const theme = useTheme();
  const [loadingCount, count] = useCounts('signals', [], true);
  const { themeStretch } = useSettings();
  const [page, setPage] = useState(1);
  const [loadingSignals, signals] = useSignals(page);

  const displaying = `Displaying ${count === 0 ? 0 : 1} - ${signals.length} of ${count}`;
  /***
   * TODOcwf: this falls back on MS geolocation
   * make a third fallback.
   *
   * ALSO not sure if s.data.enrichment maps to s.data.location
   */
  const lat =
    signals?.[0]?.data?.enrichment?.ipdata?.latitude ||
    signals[0]?.data?.location?.geoCoordinates.latitude ||
    null;

  const lng =
    signals?.[0]?.data?.enrichment?.ipdata?.longitude ||
    signals[0]?.data?.location?.geoCoordinates.longitude ||
    null;
  return (
    <Page title={`Dashboard | Insights | ${title}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h4">{title}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <ContactMap
              contacts={signals
                .map((s) => {
                  const lat =
                    s?.data?.enrichment?.ipdata?.latitude ||
                    s?.data?.location?.geoCoordinates?.longitude ||
                    null;
                  const lng =
                    s?.data?.enrichment?.ipdata?.longitude ||
                    s?.data?.location?.geoCoordinates.longitude ||
                    null;
                  const source = s?.data?.source;
                  const type = s?.data?.type;
                  const anomalies = uniqBy(s?.immediateResults, 'type').length || 0;
                  const time = s?.data?.time || s?._m?.c;
                  if (isFinite(lat) && isFinite(lng)) {
                    return { latlng: [lat, lng], source, type, anomalies, time };
                  }
                  return null;
                })
                .filter((coords) => coords !== null)}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            {signals && signals.length ? (
              <SignalsList
                title="Signals"
                subheader={displaying}
                tableData={signals}
                tableLabels={[
                  {
                    id: 'id',
                    label: 'Link'
                  },
                  { id: 'date', label: 'Date' },
                  { id: 'source', label: 'Source' },
                  { id: 'type', label: 'Type' },
                  { id: 'identity', label: 'Identity' },
                  { id: 'ip', label: 'IP' },
                  { id: 'location', label: 'Location' },
                  { id: 'anomalies', label: 'Alerts' },
                  { id: '' }
                ]}
                canLoadMore={signals.length < count}
                onLoadMore={() => !loadingSignals && setPage(page + 1)}
              />
            ) : loadingSignals ? (
              <Typography>Loading...</Typography>
            ) : (
              <Typography>No signals received yet.</Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
