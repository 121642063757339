import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Alert, Box, Container, Grid, Stack, Typography } from '@mui/material';

import LoadingScreen from '../components/LoadingScreen';
import Iconify from '...components/Iconify';
import Logo from '...components/Logo';
import Page from '...components/Page';
import useSettings from '...hooks/useSettings';
import getHttpsCallable from '...utils/getHttpsCallable';

// import { e164PhoneRegex } from '...utils/regex';

// import { useTheme } from '@mui/material/styles';

const optOut = getHttpsCallable('optOut');
const accountPreview = getHttpsCallable('accountPreview');

const title = 'Opt Out';

// TODO: Record when an approval is handled in the approval data.
// Show thank you if approval was already handled.
export default function OptOut() {
  // `sourceType` will be 'a' for accountId or `k` for publishable API key.
  // `identifierType` will be 'i' for identity or 'u' for userId.
  let { sourceType, source, identifierType, identifier } = useParams();
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(null);

  const { themeStretch } = useSettings();

  useEffect(() => {
    (async () => {
      const res = await accountPreview({
        accountId: sourceType === 'a' ? source : null,
        apiKey: sourceType === 'k' ? source : null
      });
      console.log('HAVE PREVIEW', res);
      setLoadingPreview(false);
      setPreview(res?.data);
    })();
  }, [source, sourceType]);

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    setError(null);
    const data = {};
    if (sourceType === 'a') {
      data.accountId = source;
    }
    if (sourceType === 'k') {
      data.apiKey = source;
    }
    if (identifierType === 'i') {
      data.identityId = identifier;
    }
    if (identifierType === 'u') {
      data.userId = identifier;
    }
    try {
      await optOut(data);
      setCompleted(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      setError(error);
    }
  };

  if (loadingPreview) {
    return <LoadingScreen />;
  }

  if (completed) {
    return (
      <Page title={`DFend | ${title}`}>
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            sx={{ mt: 12, gap: 2 }}
          >
            <Logo sx={{ mx: 2.5, my: 3 }} />
            <Typography variant="h4">Thank you</Typography>
            <Typography
              variant="body1"
              sx={{ mb: 5, fontSize: 18, color: 'text.secondary' }}
            >
              You are now opted out. You may close this page.
            </Typography>
          </Box>
        </Container>
      </Page>
    );
  }

  return (
    <Page title={`DFend | ${title}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Box style={{ width: 350, textAlign: 'center' }}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {/* TODO: Use logo image uploaded from dashboard. */}
                  <Logo sx={{ mx: 2.5, my: 3 }} />
                  <Iconify icon={'eva:plus-fill'} sx={{ width: 15, height: 15 }} />
                  <Logo sx={{ mx: 2.5, my: 3 }} />
                </Box>
                <Typography
                  variant="body1"
                  sx={{ mb: 5, fontSize: 18, color: 'text.secondary' }}
                >
                  {preview?.name} has partnered with DFend
                  <br />
                  for enhanced security.
                </Typography>

                {!!error && <Alert severity="error">{error.message}</Alert>}

                <Stack spacing={3}>
                  <>
                    <Typography variant="h3">Opt out of DFend</Typography>
                    <Typography>
                      Click below to opt out.
                      <br />
                      You will no longer receive security alerts.
                    </Typography>
                    <LoadingButton
                      fullWidth
                      size="large"
                      variant="contained"
                      loading={loading}
                      onClick={onSubmit}
                    >
                      Continue
                    </LoadingButton>
                    <Typography variant="caption">
                      You can opt back in at any time.
                    </Typography>
                  </>
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
