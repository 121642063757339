export const ID_NAMESPACE = 'mbr';

export const ROLE_TYPES = {
  admin: 'admin',
  user: 'user'
} as const;

export type ROLE_TYPES_KEYS = keyof typeof ROLE_TYPES;

export const ROLE_TYPES_REGEX = new RegExp(Object.values(ROLE_TYPES).join('|'));
