/* eslint-disable import/no-named-as-default-member */
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
// import { Controller, useForm } from 'react-hook-form';
// import { useNavigate } from 'react-router-dom';
import { mixed, object, string } from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  FormProvider,
  RHFTextField,
  RHFUploadSingleFile
} from '../../../components/hook-form';
// import { PATH_DASHBOARD } from '../../../routes/paths';
import content from './IntegrationMSEntra.md';
import Iconify from '...components/Iconify';
import Markdown from '...components/Markdown';
// import { MSServiceAccountKeySchema } from '...data/common/schemas/serviceAccountKeyMS';
import useAuth from '...hooks/useAuth';
import { app } from '...modules/firebase/web';

const functions = getFunctions(app);
const integrationsCreate = httpsCallable(functions, 'integrationsCreate');

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

const STEPS = ['Prepare', 'Create Integration', 'Verify'];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  top: 10,
  left: 'calc(-50% + 20px)',
  right: 'calc(50% + 20px)',
  '& .MuiStepConnector-line': {
    borderTopWidth: 2,
    borderColor: theme.palette.divider
  },
  '&.Mui-active, &.Mui-completed': {
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main
    }
  }
}));

function QontoStepIcon({ active, completed }) {
  return (
    <Box
      sx={{
        zIndex: 9,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: active ? 'primary.main' : 'text.disabled'
      }}
    >
      {completed ? (
        <Iconify
          icon={'eva:checkmark-fill'}
          sx={{ zIndex: 1, width: 20, height: 20, color: 'primary.main' }}
        />
      ) : (
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor'
          }}
        />
      )}
    </Box>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
};

// ----------------------------------------------------------------------

export default function IntegrationMSEntra({ handleClose }) {
  // const navigate = useNavigate();
  const { account } = useAuth();

  const [activeStep, setActiveStep] = useState(0);
  const [verifying, setVerifying] = useState(false);
  const [verified, setVerified] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const MSEntraIntegrationSchema = object({
    email: string().email().required('Email is required'),
    key: mixed()
      // .test()
      //TODOcwf: not sure what this is trying to test.  It's not working.
      // 'is-sak',
      // 'Key file is invalid. Please check that the file has `type`, `client_email`, `private_key`, and `private_key_id` fields.',

      // (value) => {
      //   if (!value) return true;
      //   return MSServiceAccountKeySchema.isValid(value?.json)
      // }

      .required('Key is required')
  });

  const defaultValues = {
    email: '',
    key: null
  };

  const methods = useForm({
    resolver: yupResolver(MSEntraIntegrationSchema),
    defaultValues
  });

  const {
    reset,
    // control,
    setValue,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const verifyAndCreateIntegration = async ({ email, key }) => {
    setVerifying(true);
    try {
      await integrationsCreate({ accountId: account.id, type: 'MSFetch', email, key });
      setVerifying(false);
      setVerified(true);
      enqueueSnackbar('Integration created.');
    } catch (e) {
      console.log('validation error: ', e);
      setVerifying(false);
      // setVerified(false);
    }
  };

  const onSubmit = async ({ email, key }) => {
    console.log('ON SUBMIT VALUES', email, key);
    const { json } = key;
    try {
      // put json in as integration
      await new Promise((resolve) => setTimeout(resolve, 500));

      setActiveStep(2);
      verifyAndCreateIntegration({ email, key: json });
    } catch (error) {
      console.error(error);
    }
  };

  const processFile = useCallback(
    (file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        const json = JSON.parse(e.target.result);
        setValue('key', Object.assign(file, { json }));
        /**
         * *
         * TODOcwf This is why you have to parse the json.  It's not a string, it's an object.
         * see /functions/src/integrations/create.ts
         * Don't want to change the function because i'm not sure what other functions are sending a string instead of an object.
         *
         *
         *
         * Undid anyway.  I think it was just handleIntergrationSignal that was getting a string instead of an object.
         */
        // setValue('key', Object.assign(file, { json: e.target.result }));
      };
      reader.readAsText(file);
    },
    [setValue]
  );

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      processFile(file);
    }
  };

  const onFinished = () => {
    reset();
    handleClose();
  };

  return (
    <>
      <Grid item sx={{ width: 500, mt: 4 }}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
          {STEPS.map((label, i) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={QontoStepIcon}
                sx={{
                  cursor: i < activeStep ? 'pointer' : 'initial',
                  '& .MuiStepLabel-label': {
                    typography: 'subtitle2',
                    color: 'text.disabled'
                  }
                }}
                onClick={() => i < activeStep && setActiveStep(i)}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      {activeStep === 0 && (
        <Grid
          container
          item
          justifyContent="center"
          alignItems="flex-start"
          sx={{ mt: -2 }}
        >
          <Grid item xs={12} md={8}>
            <Markdown>{content}</Markdown>
            <div>
              <Box sx={{ mt: 8, mb: 8 }}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  onClick={() => setActiveStep(1)}
                >
                  Continue
                </LoadingButton>
              </Box>
            </div>
          </Grid>
        </Grid>
      )}

      {activeStep === 1 && (
        <Grid
          container
          item
          justifyContent="center"
          alignItems="flex-start"
          sx={{ mt: 4 }}
        >
          <Grid item xs={12} md={8} sx={{ mb: 2 }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                Enter data from the previous step
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} sx={{ mb: 2 }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <div>
                    <LabelStyle>Enter the email of the MS Entra Administrator</LabelStyle>
                    <p>hint: it is dfendinc@dfendinc.onmicrosoft.com</p>
                    <RHFTextField
                      name="email"
                      label="Super Administrator's Email"
                      defaultValue="dfendinc@dfendinc.onmicrosoft.com"
                    />
                  </div>
                  <div>
                    <LabelStyle>Upload the Service Account Key</LabelStyle>
                    <RHFUploadSingleFile
                      name="key"
                      mimeType="application/json"
                      maxSize={50000}
                      onDrop={handleDrop}
                    />
                  </div>
                </Stack>
              </Card>
              <Stack direction="row" spacing={1.5} sx={{ mt: 8, mb: 8 }}>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ px: 4 }}
                  startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}
                  onClick={() => setActiveStep(0)}
                >
                  Prepare
                </Button>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  loading={isSubmitting}
                >
                  Continue
                </LoadingButton>
              </Stack>
            </FormProvider>
          </Grid>
        </Grid>
      )}

      {activeStep === 2 && (
        <Grid
          container
          item
          justifyContent="center"
          alignItems="flex-start"
          sx={{ mt: 4 }}
        >
          <Grid item xs={12} md={8} sx={{ mb: 2 }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                Verifying the integration.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} sx={{ mb: 2 }}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Box>Checking access...</Box>
                {!verifying && !verified && (
                  <Box>
                    MS Verification failed. Please go back, review the instructions and
                    try again.
                  </Box>
                )}
                {verified && <Box>Integration successful!</Box>}
              </Stack>
            </Card>
            <Stack direction="row" spacing={1.5} sx={{ mt: 8, mb: 8 }}>
              {!verifying && !verified && (
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ px: 4 }}
                  startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}
                  onClick={() => setActiveStep(0)}
                >
                  Back
                </Button>
              )}
              <LoadingButton
                fullWidth
                onClick={onFinished}
                variant="contained"
                size="large"
                disabled={verifying || !verified}
                loading={verifying}
              >
                Finished
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      )}
    </>
  );
}
