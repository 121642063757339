import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { object, string } from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Container, Grid, Stack, Typography } from '@mui/material';

import LoadingScreen from '../components/LoadingScreen';
import Iconify from '...components/Iconify';
import Logo from '...components/Logo';
import Page from '...components/Page';
import { FormProvider, RHFTextField } from '...components/hook-form';
import useIsMountedRef from '...hooks/useIsMountedRef';
import useSettings from '...hooks/useSettings';
import getHttpsCallable from '...utils/getHttpsCallable';

// import { e164PhoneRegex } from '...utils/regex';

// import { useTheme } from '@mui/material/styles';

const optIn = getHttpsCallable('optIn');
const accountPreview = getHttpsCallable('accountPreview');

const title = 'Opt In';

// TODO: Record when an approval is handled in the approval data.
// Show thank you if approval was already handled.
export default function OptIn() {
  const isMountedRef = useIsMountedRef();
  // `sourceType` will be 'a' for accountId or `k` for publishable API key.
  // `identifierType` will be 'i' for identity or 'u' for userId.
  let { sourceType, source, /* identifierType, */ identifier } = useParams();
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [preview, setPreview] = useState(null);
  const [completed, setCompleted] = useState(false);

  const { themeStretch } = useSettings();

  const defaultValues = {
    name: '',
    email: '',
    phone: ''
  };

  useEffect(() => {
    (async () => {
      const res = await accountPreview({
        accountId: sourceType === 'a' ? source : null,
        apiKey: sourceType === 'k' ? source : null
      });
      console.log('HAVE PREVIEW', res);
      setLoadingPreview(false);
      setPreview(res?.data);
    })();
  }, [source, sourceType]);

  const methods = useForm({
    resolver: yupResolver(
      object({
        name: string().optional(),
        email: string('Email must be a valid email address').email().required(),
        phone: string('Phone must be a valid phone number with country code').optional()
      })
    ),
    defaultValues
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = methods;

  const onSubmit = async (data, type) => {
    console.log('onSubmit calle');
    const _data = {
      ...data,
      // accountId: sourceType === 'a' ? source : null,
      // apiKey: sourceType === 'k' ? source : null,
      userId: identifier
    };
    if (!_data.phone) {
      delete _data.phone;
    }
    if (sourceType === 'a') {
      _data.accountId = source;
    }
    if (sourceType === 'k') {
      _data.apiKey = source;
    }
    if (isSubmitting) return;
    try {
      await optIn(_data);
      setCompleted(true);
    } catch (error) {
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  if (loadingPreview) {
    return <LoadingScreen />;
  }

  if (completed) {
    return (
      <Page title={`DFend | ${title}`}>
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            sx={{ mt: 12, gap: 2 }}
          >
            <Logo sx={{ mx: 2.5, my: 3 }} />
            <Typography variant="h4">Thank you</Typography>
            <Typography
              variant="body1"
              sx={{ mb: 5, fontSize: 18, color: 'text.secondary' }}
            >
              You are now opted in. You may close this page.
            </Typography>
          </Box>
        </Container>
      </Page>
    );
  }

  return (
    <Page title={`DFend | ${title}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Box style={{ width: 350, textAlign: 'center' }}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {/* TODO: Use logo image uploaded from dashboard. */}
                  <Logo sx={{ mx: 2.5, my: 3 }} />
                  <Iconify icon={'eva:plus-fill'} sx={{ width: 15, height: 15 }} />
                  <Logo sx={{ mx: 2.5, my: 3 }} />
                </Box>
                <Typography
                  variant="body1"
                  sx={{ mb: 5, fontSize: 18, color: 'text.secondary' }}
                >
                  {preview?.name} has partnered with DFend
                  <br />
                  for enhanced security.
                </Typography>

                {!!errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )}
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={3}>
                    <>
                      <Typography variant="h3">Opt into DFend</Typography>
                      <Typography>Enter contact info for security alerts.</Typography>
                      <RHFTextField name="name" label="Full name" />
                      <RHFTextField name="email" label="Contact email" />
                      <RHFTextField
                        name="phone"
                        label="Contact phone w/ country code (+15555555555)"
                      />
                      <LoadingButton
                        fullWidth
                        size="large"
                        variant="contained"
                        loading={isSubmitting}
                        type="submit"
                      >
                        Continue
                      </LoadingButton>
                      <Typography variant="caption">
                        You can opt out at any time.
                      </Typography>
                    </>
                  </Stack>
                </FormProvider>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
