import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { Box } from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';

import Iconify from '../Iconify';
//
import Image from '../Image';
import BlockContent from './BlockContent';
import RejectionFiles from './RejectionFiles';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' }
}));

const previewSx = {
  top: 8,
  left: 8,
  borderRadius: 1,
  position: 'absolute',
  width: 'calc(100% - 16px)',
  height: 'calc(100% - 16px)'
};

const FileBoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[900]
}));

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object
};

export default function UploadSingleFile({ error = false, file, helperText, sx, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other
  });

  console.log('FILE:', file);
  const isImage = /image/.test(file?.type);
  const isJson = /json/.test(file?.type);
  console.log('Is image?', isImage);
  console.log('Is json?', isJson);

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          }),
          ...(file && isImage && {
            padding: '12% 0'
          })
        }}
      >
        <input {...getInputProps()} />

        <BlockContent />

        {file && isJson && (
          <FileBoxStyle>
            <Iconify icon={'eva:file-fill'} /> {file.name}
          </FileBoxStyle>
        )}

        {file && isImage && (
          <Image
            alt="file preview"
            src={typeof file === 'string' ? file : file.preview}
            sx={previewSx}
          />
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  );
}
