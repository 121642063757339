import { Link as RouterLink } from 'react-router-dom';

import { Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import Page from '...components/Page';
import LogoOnlyLayout from '...layouts/LogoOnlyLayout';
import { PATH_AUTH } from '...routes/paths';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function MagicLinkSent() {


  return (
    <Page title="Magic Link Sent">
      <LogoOnlyLayout />

      <Container>
        <ContentStyle sx={{ textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            Magic Link Sent
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 5 }}>
            Please check your email and click the magic link to log in without a password.
          </Typography>

          <Button fullWidth size="large" component={RouterLink} to={PATH_AUTH.login} sx={{ mt: 1 }}>
            Back
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
