import { getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import useAuth from './useAuth';
import results from '...data/results/collections/results';

const cache = {};
// TODO: Connect these to a context (and counts as well) so we don't have to reload with CSR.
const pageSize = 25;
export default function useResults(page = 1) {
  const { account, isInitialized, isAuthenticated } = useAuth();

  const initData = account?.id ? (cache[account.id] || []).slice(0, page * pageSize) : [];

  const [data, setData] = useState(initData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isInitialized || !isAuthenticated) return;
    if (isAuthenticated && !account?.id) {
      return;
    }
    (async () => {
      setLoading(true);
      const q = query(
        results,
        where('account', '==', account?.id),
        orderBy('_m.c', 'desc'),
        limit((page || 1) * pageSize)
      );
      const snapshot = await getDocs(q);
      const d = [];
      snapshot.forEach((doc) => d.push({ id: doc.id, ...doc.data() }));
      setData(d);
      cache[account.id] = d;
      setLoading(false);
    })();
  }, [account?.id, page, isInitialized, isAuthenticated]);

  return [loading, data];
}
