import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Divider, IconButton, InputAdornment, Link, Stack, Typography } from '@mui/material';

import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import * as google from '...contexts/Auth/providers/google';
import * as magicLink from '...contexts/Auth/providers/magicLink';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();

  const [loadingGoogle, setLoadingGoogle] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const defaultValues = {
    email: ''
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = methods;

  const onSubmit = async (data, type) => {
    if (isSubmitting || loadingGoogle) return;
    try {
      if (type !== 'google') {
        // Don't wrap in `register` because this happens on magic link page.
        await magicLink.send(data.email);
        navigate('/auth/magic-link-sent');
      } else {
        setLoadingGoogle(true);
        await google.signIn();
      }
    } catch (error) {
      setLoadingGoogle(false);
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <Box>
      {!!errors.afterSubmit && (
        <Alert severity="error">{errors.afterSubmit.message}</Alert>
      )}
      <LoadingButton
        fullWidth
        size="large"
        color="inherit"
        variant="outlined"
        onClick={() => onSubmit(null, 'google')}
        loading={loadingGoogle}
      >
        <Iconify icon={'eva:google-fill'} color="#DF3E30" width={24} height={24} />
      </LoadingButton>
      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <>
            <RHFTextField name="email" label="Email address" />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Send Magic Link
            </LoadingButton>
          </>
        </Stack>
      </FormProvider></Box>
  );
}
