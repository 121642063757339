import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';

import Iconify from '...components/Iconify';
import Logo from '...components/Logo';
import Page from '...components/Page';
import useApproval from '...hooks/useApproval';
import useSettings from '...hooks/useSettings';
import getHttpsCallable from '...utils/getHttpsCallable';

// import { useTheme } from '@mui/material/styles';

const approval = getHttpsCallable('approval');

const title = 'Verify';

// TODO: Record when an approval is handled in the approval data.
// Show thank you if approval was already handled.
export default function Verify() {
  let { id } = useParams();
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [completed, setCompleted] = useState(false);

  // const { user } = useAuth();

  // const theme = useTheme();
  const { themeStretch } = useSettings();

  const [loading, a] = useApproval(id);

  console.log('Approval?', a);

  // TODO: Handle errors.
  const approve = async () => {
    console.log('accept');
    setLoadingApprove(true);
    await approval({ id, approved: true });
    setCompleted(true);
  };

  const reject = async () => {
    console.log('reject');
    setLoadingReject(true);
    await approval({ id, rejected: true });
    setCompleted(true);
  };

  let type;
  let message = '';

  // TODO: When action is performed, show confirmation that they may close page.

  if (['IMPOSSIBLE_TRAVEL', 'NEW_LOCATION', 'VPN_SWITCH'].includes(a?.type)) {
    type = 'LOCATION';
    message = 'Someone accessed your account from the following location:';
  }
  if (a?.type === 'NEW_DEVICE') {
    type = 'DEVICE';
    message = 'Unrecognized device being used to access your account:';
  }
  if (a?.type === 'TOR_SWITCH') {
    type = 'TOR';
    message = 'Someone is accessing your account from the Tor network.';
  }

  const city = a?.fromSignal?.city;
  const region = a?.fromSignal?.region;
  const country = a?.fromSignal?.country;
  const flag = a?.fromSignal?.emojiFlag;

  if (completed) {
    return (
      <Page title={`DFend | ${title}`}>
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            sx={{ mt: 12, gap: 2 }}
          >
            <Logo sx={{ mx: 2.5, my: 3 }} />
            <Typography variant="h4">Thank you</Typography>
            <Typography
              variant="body1"
              sx={{ mb: 5, fontSize: 18, color: 'text.secondary' }}
            >
              We have saved your response. You may close this page.
            </Typography>
          </Box>
        </Container>
      </Page>
    );
  }

  return (
    <Page title={`DFend | ${title}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container>
          {/* <Grid item xs={12} md={3} sx={{ p: 2 }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <Logo sx={{ mx: 2.5, my: 3 }} /> + <Logo sx={{ mx: 2.5, my: 3 }} />
            </Box>
            <Typography variant="body1" sx={{ mb: 5, fontSize: 20 }}>
              {a?.account?.name} has partnered with DFend to provide enhanced security to
              its users.
            </Typography>
          </Grid> */}
          <Grid item xs={12}>
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  height: '100vh',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Typography>Loading...</Typography>
              </Box>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Box style={{ width: 350, textAlign: 'center' }}>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Logo sx={{ mx: 2.5, my: 3 }} />
                    {/* <Box sx={{ mx: 2.5, my: 3 }}>
                      <img src="/assets/stitch-logo.png" />
                    </Box> */}
                    <Iconify icon={'eva:plus-fill'} sx={{ width: 15, height: 15 }} />
                    <Logo sx={{ mx: 2.5, my: 3 }} />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{ mb: 5, fontSize: 18, color: 'text.secondary' }}
                  >
                    {a?.account?.name} has partnered with DFend
                    <br />
                    for enhanced security.
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 3,
                    mt: 4
                  }}
                >
                  <Typography variant="h4">
                    We&apos;ll need you to verify to continue
                  </Typography>
                  <Typography variant="body1">{message}</Typography>
                  <Card sx={{ p: 3 }}>
                    {type === 'LOCATION' && (
                      <Typography variant="subtitle2">
                        {city}, {region}, {country} {flag}
                      </Typography>
                    )}
                  </Card>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 3,
                      mt: 5,
                      mb: 5
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      onClick={approve}
                      disabled={loadingReject}
                      loading={loadingApprove}
                    >
                      Yes, this is me
                    </LoadingButton>
                    <LoadingButton
                      onClick={reject}
                      disabled={loadingApprove}
                      loading={loadingReject}
                    >
                      No, this is not me
                    </LoadingButton>
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{
                        color: 'text.secondary',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Iconify icon={'eva:info-fill'} sx={{ width: 15, height: 15 }} />
                      &nbsp;&nbsp;Until you verify, we have recommended to pause activity
                      on your account.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
