import { node } from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  // console.log('[GuestGuard] pathname', pathname);

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  if (!isInitialized /* && pathname !== '/auth/magic-link' */) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
