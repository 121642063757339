import { getFunctions, httpsCallable } from 'firebase/functions';

import { app } from '...modules/firebase/web';

const functions = getFunctions(app);


const getHttpsCallable = httpsCallableFuncName => httpsCallable(functions, httpsCallableFuncName);

export default getHttpsCallable;
