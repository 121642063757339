import { onSnapshot, query, setDoc, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';

import Page from '../../components/Page';
import useCounts from '../../hooks/useCounts';
import useSettings from '../../hooks/useSettings';
import AppNewInvoice from '../../sections/@dashboard/insights/Anomalies';
import useResults from '...hooks/useResults';

// import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const title = 'Alerts';

export default function Anomalies() {
  const [loadingCount, count] = useCounts('results', [], true);
  const [page, setPage] = useState(1);
  const [loadingAnomalies, anomalies] = useResults(page);
  // const { user } = useAuth();

  // const theme = useTheme();

  const { themeStretch } = useSettings();

  const displaying = `Displaying ${count === 0 ? 0 : 1} - ${
    anomalies.length
  } of ${count}`;

  return (
    <Page title={`Dashboard | Insights | ${title}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ mb: 2 }} justifyContent="space-between">
          <Typography variant="h4">{title}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            {anomalies && anomalies.length ? (
              <AppNewInvoice
                title="Alerts"
                subheader={displaying}
                tableData={anomalies}
                tableLabels={[
                  { id: 'id', label: 'Link' },
                  { id: 'date', label: 'Date' },
                  { id: 'identity', label: 'Identity' },
                  { id: 'type', label: 'Type' },
                  { id: 'signals', label: 'Signals' },
                  { id: '' }
                ]}
                canLoadMore={anomalies.length < count}
                onLoadMore={() => !loadingAnomalies && setPage(page + 1)}
              />
            ) : loadingAnomalies ? (
              <Typography>Loading...</Typography>
            ) : (
              <Typography>No anomalies detected yet.</Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
