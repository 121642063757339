import { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

// components
import LoadingScreen from '../components/LoadingScreen';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
import AuthGuard from '../guards/AuthGuard';
// guards
import GuestGuard from '../guards/GuestGuard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';
// layouts
import MainLayout from '../layouts/main';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        {
          path: 'magic-link',
          element: <MagicLink />
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'magic-link-sent', element: <MagicLinkSent /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'overview', element: <Overview /> },
        {
          path: 'insights',
          children: [
            {
              element: <Navigate to="/dashboard/insights/signals" replace />,
              index: true
            },
            { path: 'signals', element: <InsightsSignals /> },
            { path: 'signals/:id', element: <InsightsSignal /> },
            { path: 'alerts', element: <InsightsAnomalies /> },
            { path: 'alerts/:id', element: <InsightsAnomaly /> },
            { path: 'identities', element: <InsightsIdentities /> },
            { path: 'identities/:id', element: <InsightsIdentity /> }
          ]
        },
        {
          path: 'setup',
          children: [
            { element: <Navigate to="/dashboard/setup/api-keys" replace />, index: true },
            { path: 'api-keys', element: <SetupApiKeys /> },
            { path: 'sdk', element: <SetupSdk /> },
            { path: 'connections', element: <SetupIntegrations /> },
            { path: 'policies', element: <SetupRouting /> }
          ]
        },
        {
          path: 'manage',
          children: [
            { element: <Navigate to="/dashboard/manage/users" replace />, index: true },
            { path: 'users', element: <ManageTeam /> },
            { path: 'billing', element: <ManageBilling /> }
          ]
        },
        // Old.
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },
        { path: 'permission-denied', element: <PermissionDenied /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      children: [
        { element: <Navigate to="/auth/register" replace />, index: true } //<MainLayout />,
      ]
      // children: [
      //   { element: <HomePage />, index: true },
      //   { path: 'about-us', element: <About /> },
      //   { path: 'contact-us', element: <Contact /> },
      //   { path: 'faqs', element: <Faqs /> }
      // ]
    },
    { path: '/verify/:id', element: <Verify /> },
    {
      path: '/opt-in/:sourceType/:source/:identifierType/:identifier',
      element: <OptIn />
    },
    {
      path: '/opt-out/:sourceType/:source/:identifierType/:identifier',
      element: <OptOut />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// OPT IN OR OUT
const OptIn = Loadable(lazy(() => import('../pages/OptIn')));
const OptOut = Loadable(lazy(() => import('../pages/OptOut')));

// VERIFY
const Verify = Loadable(lazy(() => import('../pages/Verify')));

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const MagicLink = Loadable(lazy(() => import('../pages/auth/MagicLink')));
const MagicLinkSent = Loadable(lazy(() => import('../pages/auth/MagicLinkSent')));

// DASHBOARD
const Overview = Loadable(lazy(() => import('../pages/dashboard/Overview')));
const InsightsSignals = Loadable(
  lazy(() => import('../pages/dashboard/InsightsSignals'))
);
const InsightsSignal = Loadable(lazy(() => import('../pages/dashboard/InsightsSignal')));
const InsightsAnomalies = Loadable(
  lazy(() => import('../pages/dashboard/InsightsAnomalies'))
);
const InsightsAnomaly = Loadable(
  lazy(() => import('../pages/dashboard/InsightsAnomaly'))
);
const InsightsIdentities = Loadable(
  lazy(() => import('../pages/dashboard/InsightsIdentities'))
);
const InsightsIdentity = Loadable(
  lazy(() => import('../pages/dashboard/InsightsIdentity'))
);
const SetupApiKeys = Loadable(lazy(() => import('../pages/dashboard/SetupApiKeys')));
const SetupSdk = Loadable(lazy(() => import('../pages/dashboard/SetupSdk')));
const SetupIntegrations = Loadable(
  lazy(() => import('../pages/dashboard/SetupIntegrations'))
);
const SetupRouting = Loadable(lazy(() => import('../pages/dashboard/SetupRouting')));
const ManageTeam = Loadable(lazy(() => import('../pages/dashboard/ManageTeam')));
const ManageBilling = Loadable(lazy(() => import('../pages/dashboard/ManageBilling')));

// GENERAL (OLD)
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(
  lazy(() => import('../pages/dashboard/GeneralEcommerce'))
);
const GeneralAnalytics = Loadable(
  lazy(() => import('../pages/dashboard/GeneralAnalytics'))
);
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDenied'))
);

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
