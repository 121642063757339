import { bool, object } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { Box } from '@mui/material';

// import { useTheme } from '@mui/material/styles';

const Logo = ({ disabledLink = false, sx }) => {
  // const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 75 86"
        style={{
          enableBackground: 'new 0 0 75 86',
          width: '100%',
          height: '100%'
        }}
        xmlSpace="preserve"
      >
        <path
          d="M72.8 20.1 39.7 1c-1.4-.8-3-.8-4.4 0L2.2 20.1C.8 20.9 0 22.3 0 23.9v26.2c0 9 4.8 17.4 12.7 21.9l22.6 13c1.4.8 3 .8 4.4 0l22.7-13.1C70.2 67.4 75 59.1 75 50.1V23.9c0-1.6-.9-3-2.2-3.8zM37.5 9.9l13.2 7.6L8.8 42V26.4L37.5 9.9zM8.9 52.1l50.5-29.6 6.7 3.9v7.7L15.9 63.6c-3.8-2.7-6.4-6.8-7-11.5zm49 12.2L37.5 76.1l-12.9-7.5 41.6-24.4v5.7c0 6-3.2 11.5-8.3 14.4z"
          style={{
            fill: '#0056fb'
          }}
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

Logo.propTypes = {
  disabledLink: bool,
  sx: object
};

export default Logo;
