import { getAuth, sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth';

import { onRegister, onSignIn } from '../hooks';
import { app } from '...modules/firebase/web';
import getHttpsCallable from '...utils/getHttpsCallable';

const auth = getAuth(app);
const authRegisterUser = getHttpsCallable('authRegisterUser');

export const send = async (email, queryString = '') => {
  const actionSettings = {
    url: `${process.env.ROOT_URL}/auth/magic-link${queryString}`,
    handleCodeInApp: true
  };
  await sendSignInLinkToEmail(auth, email, actionSettings);
  window.localStorage.setItem('magicLinkEmail', email);
};

export const register = async ({ displayName = '', company, email }) => {
  await send(
    email,
    `?n=${encodeURIComponent(displayName)}&c=${encodeURIComponent(company)}`
  );
};

export const signIn = async (email, newUserData) => {
  const userCredential = await signInWithEmailLink(auth, email, window.location.href);
  window.localStorage.removeItem('magicLinkEmail');
  if (newUserData?.n && newUserData?.c) {
    const result = await authRegisterUser({
      displayName: newUserData.n,
      company: newUserData.c
    });
    console.log('authRegisterUser response', result.data);
    onRegister('email-link', userCredential);
  } else {
    onSignIn('email-link', userCredential);
  }
  return userCredential;
};
