// TODO: set up Google Analytics
// mock
const dataLayerPush = (event, data) => {
  console.log('[analytics]: Push called with:', event, data);
}

export const onSignIn = async (type, userCredential) => {
  const { user } = userCredential;
  const { claims } = await user.getIdTokenResult();
  dataLayerPush('signIn', {
    type,
    membership: claims?.roles?.accounts ? Object.keys(claims.roles.accounts).join('|') : ''
  });
};

export const onRegister = data => {
  dataLayerPush('signUp', data);
};
