import { onSnapshot, orderBy, query, setDoc, where } from 'firebase/firestore';
import { isFinite, uniqBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';
import { ContactMap } from '../../sections/contact';
import Iconify from '...components/Iconify';
import Label from '...components/Label';
import useIdentity from '...hooks/useIdentity';
import useResult from '...hooks/useResult';

// import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const title = 'Alert';

const SectionContainer = ({ title, children }) => (
  <Stack spacing={2} sx={{ mt: 4, mb: 4 }}>
    {!!title && (
      <div>
        <Typography variant="h6" sx={{ color: 'text.primary' }}>
          {title}
        </Typography>
      </div>
    )}
    {children}
  </Stack>
);

const Section = ({ title, caption, body, children }) => (
  <Stack spacing={1}>
    {!!title && (
      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {title}
      </Typography>
    )}
    {!!caption && (
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        {caption}
      </Typography>
    )}
    {!!body && (
      <Typography variant="body2" sx={{ color: 'text.primary' }}>
        {body}
      </Typography>
    )}
    {children}
  </Stack>
);

const dateFormat = 'YYYY/MM/DD [at] h:mm:ss a';
const getDate = (d) => moment(d?.toDate()).format(dateFormat);
const getLinkExp = (d) => moment(d?.toDate()).add(24, 'hours').format(dateFormat);
// ---------------------------------------------

export default function InsightsAnomaly() {
  let { id } = useParams();

  // const { user } = useAuth();

  const theme = useTheme();

  const { themeStretch } = useSettings();

  const [loadingResult, r] = useResult(id);
  const [loadingIdentity, i] = useIdentity(r?.identity?.id);

  console.log('Result?', r);
  console.log('Identity?', i);

  // ---------------------------------------------

  const ipdata = r?.data?.enrichment?.ipdata;

  const created = moment(r?._m?.c?.toDate()).format('YYYY/MM/DD [at] h:mm:ss a');
  // const source = r?.data?.source;
  const type = r?.type?.replace('_', ' ');
  // const trusted = r?.trusted;

  // Location.
  const city = ipdata?.city;
  const region = ipdata?.region;
  const regionType = ipdata?.region_type;
  const country = ipdata?.country_name;
  const flag = ipdata?.emoji_flag;
  const latitude = ipdata?.latitude;
  const longitude = ipdata?.longitude;

  // Network.
  const userAgent = r?.data?.userAgent;
  const ip = r?.data?.ip;
  const companyName = ipdata?.company?.name;
  // const companyNetwork = ipdata?.company?.network;
  const isVpn = ipdata?.threat?.is_vpn;
  // const isAnon = ipdata?.threat?.is_anonymous;
  // const vpnScore = ipdata?.threat?.scores?.vpn_score;

  console.log('Signals:', r?.signals);
  // Map.
  let coords =
    r?.signals
      ?.filter((s) => {
        const ipdata = s?.data?.enrichment?.ipdata;
        return isFinite(ipdata?.latitude) && isFinite(ipdata?.longitude);
      })
      .map((s) => {
        const ipdata = s?.data?.enrichment?.ipdata;
        const city = ipdata?.city;
        const region = ipdata?.region;
        const regionType = ipdata?.region_type;
        const country = ipdata?.country_name;
        const flag = ipdata?.emoji_flag;
        const latitude = ipdata?.latitude;
        const longitude = ipdata?.longitude;
        return {
          latlng: [latitude, longitude],
          source: s?.data?.source,
          type: s?.data?.type,
          anomalies: 1,
          time: getDate(s?.data?.time),
          city,
          region,
          regionType,
          country,
          flag,
          latitude,
          longitude
        };
      }) || [];

  // Identity.
  const identity = r?.identity;
  const ide = i?.identifiers;
  const names = ide?.names?.join(', ');
  const emails = ide?.emails?.join(', ');
  const phones = ide?.phones?.join(', ');
  const userIds = ide?.userIds?.join(', ');

  // ---------------------------------------------

  return (
    <Page title={`Dashboard | Insights | ${title}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={r?.level !== 'INFO' ? 8 : 12}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          {r?.level !== 'INFO' && (
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
                //pr: 1.5
              }}
            >
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color="error"
              >
                {r?.level === 'CRITICAL' ? 'HIGH RISK' : 'MEDIUM RISK'}
              </Label>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <div>
              <Typography variant="h5" style={{ display: 'inline' }}>
                Risk Score
              </Typography>
              <Typography style={{ display: 'inline' }}>Current</Typography>
            </div>
            <Divider />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Divider />
            <SectionContainer>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Section title="Type">
                    <div>
                      {!!type && (
                        <Label
                          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                          color="error"
                        >
                          {type}
                        </Label>
                      )}
                    </div>
                  </Section>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Section title="Created" body={created} />
                </Grid>
                <Grid item xs={12}>
                  <Section title="Triggered By">
                    {r?.signals.map((s, i) => (
                      <Grid key={i} container>
                        <Grid item xs={6}>
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                          >
                            {i === 0 ? 'SIGNAL' : 'PREVIOUS SIGNAL'}
                          </Label>
                          <Link to={`/dashboard/insights/signals/${s?.id}`}>
                            <IconButton sx={{ ml: 0.5 }}>
                              <Iconify
                                icon={'eva:external-link-fill'}
                                sx={{ width: 18, height: 18 }}
                              />
                            </IconButton>
                          </Link>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            variant="body2"
                            sx={{
                              color: 'text.primary',
                              ml: 1.5
                            }}
                          >
                            {getDate(s?.data?.time)}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Section>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Section title="Source" body={source} />
                </Grid> */}
                {/* <Grid item xs={12} md={6}>
                  <Section title="IP" body={ip} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Section title="Company" body={companyName} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Section title="Recognized VPN" body={`${isVpn}`} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Section title="User Agent" body={`${userAgent || 'none'}`} />
                </Grid> */}
                {/* <Grid item xs={12} md={6}>
                  <Section title="Trusted" body={`${trusted}`} />
                </Grid> */}
              </Grid>
            </SectionContainer>
            <Divider />
            <SectionContainer title="Recommendations">
              {!r?.recommendations?.length && (
                <Grid container>
                  <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>
                    NONE
                  </Label>
                </Grid>
              )}
              {r?.recommendations?.map((rec, i) => (
                <Grid key={i} container>
                  <Grid item xs={12} md={6}>
                    <Section title="Type">
                      <div>
                        <Label
                          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                          color="info"
                        >
                          {rec.type.replaceAll('_', ' ')}
                        </Label>
                      </div>
                    </Section>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ pb: 3 }}>
                    <Section title="Description" body={rec.description} />
                  </Grid>
                  {rec.template && (
                    <Grid item xs={12} md={6}>
                      <Section title="Template" body={rec.template} />
                    </Grid>
                  )}
                  {rec.type === 'NOTIFY_USER_WITH_LINK' && (
                    <Grid item xs={12} md={6}>
                      <Section title="Link Expires" body={getLinkExp(r?._m?.c)} />
                    </Grid>
                  )}
                  {rec.ban && (
                    <>
                      {!!rec.ban?.action && (
                        <Grid item xs={12} md={6}>
                          <Section title="Ban Action" body={rec.ban?.action} />
                        </Grid>
                      )}
                      {!!rec.ban?.until && (
                        <Grid item xs={12} md={6}>
                          <Section
                            title="Ban Until (Unix Millis)"
                            body={rec.ban?.until}
                          />
                        </Grid>
                      )}
                      {!!rec.ban?.ip && (
                        <Grid item xs={12} md={6}>
                          <Section title="Ban IP" body={rec.ban?.ip} />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              ))}
            </SectionContainer>
            <Divider />
            <SectionContainer
              title={
                <>
                  <span>Identity</span>
                  <Link to={`/dashboard/insights/identities/${identity?.id}`}>
                    <IconButton sx={{ ml: 0.5 }}>
                      <Iconify
                        icon={'eva:external-link-fill'}
                        sx={{ width: 18, height: 18 }}
                      />
                    </IconButton>
                  </Link>
                </>
              }
            >
              {!!names && <Section title="Names" body={names} />}
              {!!emails && <Section title="Emails" body={`${emails}`} />}
              {!!phones && <Section title="Phone Numbers" body={`${phones}`} />}
              {!!userIds && <Section title="User IDs" body={`${userIds}`} />}
            </SectionContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              {!!r && (
                <ContactMap
                  contacts={coords}
                  initialViewState={
                    coords.length
                      ? {
                          latitude: coords[0].latlng[0],
                          longitude: coords[0].latlng[1],
                          zoom: 7
                        }
                      : null
                  }
                />
              )}
            </Box>
            <SectionContainer title="Location">
              {coords.length ? (
                coords.map(
                  (
                    { city, region, regionType, country, flag, latitude, longitude },
                    i
                  ) => (
                    <>
                      <Stack key={i} spacing={3}>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Section title="City" body={city} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Section title={regionType} body={region} />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12} md={12}>
                            <Section title="Country" body={`${country} ${flag}`} />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Section title="Latitude" body={latitude} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Section title="Longitude" body={longitude} />
                          </Grid>
                        </Grid>
                      </Stack>
                      {coords.length > 1 && <Divider />}
                    </>
                  )
                )
              ) : (
                <Grid container>
                  <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>
                    NONE
                  </Label>
                </Grid>
              )}
            </SectionContainer>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
