export const ID_NAMESPACE = 'res';

export const RESULTS_PATH = 'results';

// REVIEW: Couldn't a result include more than one type? Like IMPOSSIBLE_TRAVEL + VPN_SWITCH.
export const RESULT_TYPES = {
  // We can lazily send them an identity ID as they send signals.
  // This will include the identity ID, which they can query at any time (now or off later results)
  // to connect the identity ID with user identifiers.
  NEW_IDENTITY: 'NEW_IDENTITY',
  IDENTITY_OPTED_IN: 'IDENTITY_OPTED_IN',
  IDENTITY_OPTED_OUT: 'IDENTITY_OPTED_OUT',
  IP_MANY_IDENTITIES: 'IP_MANY_IDENTITIES',
  IP_SIMILARITY: 'IP_SIMILARITY', // User is on a significantly different IP address than we've seen.
  IMPOSSIBLE_TRAVEL: 'IMPOSSIBLE_TRAVEL', // User is at a location they couldn't have traveled to.
  NEW_DEVICE: 'NEW_DEVICE', // User is on a device we've never seen before.
  NEW_LOCATION: 'NEW_LOCATION', // User is at a location they've never been at before (minimum distance).
  VPN_SWITCH: 'VPN_SWITCH', // User is using a VPN provider that they haven't used before.
  TOR_SWITCH: 'TOR_SWITCH', // User was not on Tor, now is.
  CARRIER_SWITCH: 'CARRIER_SWITCH', // If on mobile carrier network, on one we haven't seen.
  BAD_ACTOR: 'BAD_ACTOR', // One of known bad actors.
  BAD_DEVICE: 'BAD_DEVICE',
  COMPROMISED_PASSWORD: 'COMPROMISED_PASSWORD', // User was found using a known compromised password.
  FAILED_LOGINS_EXCEEDED: 'FAILED_LOGINS_EXCEEDED', // User has exceeded the max failed logins attempt.
  // TODO: Handle user response.
  // User response types: 'IMPOSSIBLE_TRAVEL_ACCEPTED', 'DEVICE_ACCEPTED', 'LOCATION_ACCEPTED',
  // 'VPN_ACCEPTED', 'TOR_ACCEPTED', 'CARRIER_ACCEPTED', and REJECTED variants of those.
  // USER_INVOLVEMENT_RESPONSE: 'USER_INVOLVEMENT_RESPONSE' // User has responded to a notification using a DFend embedded link.
  USER_INVOLVEMENT_EXPIRED: 'USER_INVOLVEMENT_EXPIRED' // User didn't respond in a given time period. Take further action.
} as const;

// REVIEW: Actual use, usefulness, applicability of levels.
export const LEVEL_TYPES = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  CRITICAL: 'CRITICAL' // Perhaps critical would relate to USER_RESPONSE type.
} as const;

export const RECOMMENDATION_TYPES = {
  TERMINATE_SESSION: 'TERMINATE_SESSION',
  NOTIFY_USER: 'NOTIFY_USER',
  NOTIFY_USER_WITH_LINK: 'NOTIFY_USER_WITH_LINK',
  INVALIDATE_PASSWORD: 'INVALIDATE_PASSWORD',
  FLAG_FOR_NEXT: 'FLAG_FOR_NEXT',
  BAN_IP: 'BAN_IP',
  BAN_DEVICE: 'BAN_DEVICE', // REVIEW: Make sure this doesn't cause problems.
  BAN_ACTION: 'BAN_ACTION'
} as const;

export const BAN_ACTION_TYPES = {
  ALL: 'ALL',
  LOGIN: 'LOGIN',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT'
} as const;

export type RESULT_TYPES_KEYS = keyof typeof RESULT_TYPES;
export type LEVEL_TYPES_KEYS = keyof typeof LEVEL_TYPES;
export type RECOMMENDATION_TYPES_KEYS = keyof typeof RECOMMENDATION_TYPES;
export type BAN_ACTION_TYPES_KEYS = keyof typeof BAN_ACTION_TYPES;

export const RESULT_TYPES_REGEX = new RegExp(Object.values(RESULT_TYPES).join('|'));
export const LEVEL_TYPES_REGEX = new RegExp(Object.values(LEVEL_TYPES).join('|'));
export const RECOMMENDATION_TYPES_REGEX = new RegExp(
  Object.values(RECOMMENDATION_TYPES).join('|')
);
export const BAN_ACTION_TYPES_REGEX = new RegExp(
  Object.values(BAN_ACTION_TYPES).join('|')
);
