import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { array, bool, object, string } from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Label from '../../components/Label';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
import Iconify from '...components/Iconify';
import {
  FormProvider,
  RHFCheckbox,
  RHFSelect,
  RHFTextField
} from '...components/hook-form';
import { getMetadata } from '...data/common/schemas/_m';
import { RESULT_TYPES, RESULT_TYPES_REGEX } from '...data/results/constants';
import subscriptionsRef from '...data/subscriptions/collections/subscriptions';
import { app } from '...modules/firebase/web';
import getHttpsCallable from '...utils/getHttpsCallable';

const firestore = getFirestore(app);
const setUserInvolvement = getHttpsCallable('setUserInvolvement');

const title = 'Policies';

const resultTypesDescriptions = {
  NEW_IDENTITY: 'We created a new DFend Identity for one of your users.',
  IP_MANY_IDENTITIES: 'Many different users are logged into your app from the same IP.',
  IP_SIMILARITY:
    "User's IP address is numerically dissimilar from others they have used.",
  IMPOSSIBLE_TRAVEL:
    'User could not have traveled between two locations at a realistic speed.',
  NEW_DEVICE: "User is on a device we've never seen before.",
  NEW_LOCATION: "User is at a location we've never seen before (city or region).",
  VPN_SWITCH: "User is using a VPN provider that they haven't used before.",
  TOR_SWITCH: "We haven't seen this user on Tor network, but now they are on it.",
  CARRIER_SWITCH:
    "User is on a mobile carrier network that they haven't used before (only triggered along with NEW DEVICE or IMPOSSIBLE TRAVEL).",
  BAD_ACTOR: "User's IP has been used by a bad actor.",
  BAD_DEVICE:
    "User's account is being accessed from a device was previously rejected by the user.",
  COMPROMISED_PASSWORD:
    'User logged into an account using a known compromised password while using Chrome browser or ChromeOS (Google Workspace integration).',
  FAILED_LOGINS_EXCEEDED:
    'User has exceeded the max failed logins within a given time period.'
  // TODO: Handle user response.
  // User response types: 'IMPOSSIBLE_TRAVEL_ACCEPTED', 'DEVICE_ACCEPTED', 'LOCATION_ACCEPTED',
  // 'VPN_ACCEPTED', 'TOR_ACCEPTED', 'CARRIER_ACCEPTED', and REJECTED variants of those.
  // USER_INVOLVEMENT_RESPONSE: 'USER_INVOLVEMENT_RESPONSE' // User has responded to a notification using a DFend embedded link.
  // USER_INVOLVEMENT_EXPIRED: 'USER_INVOLVEMENT_EXPIRED' // User didn't respond in a given time period. Take further action.
};

const updateSubscription = async (id, data) => {
  console.log('updating subscription:', id, data);
  const ref = doc(firestore, subscriptionsRef.id, id);
  await updateDoc(ref, data);
};

const createSubscription = async (data) => {
  // TODO
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const VerificationSection = ({ children }) => (
  <Stack spacing={2}>
    <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
      Verification
    </Typography>
    <Typography variant="body2" sx={{ color: 'text.primary' }}>
      We can reach out directly to your users with a link to verify new locations,
      devices, and other types of abnormal usage. We will send the results to your
      webhook.
      <br />
      <br />
      <strong>
        Users must opt in. You can set this up using our client SDK. Please see the
        documentation.
      </strong>
    </Typography>
    {children}
  </Stack>
);

const EmailSection = ({ children }) => (
  <Stack spacing={2}>
    <Typography variant="overline" sx={{ color: 'text.secondary' }}>
      Emails
    </Typography>
    <Typography variant="body2" sx={{ color: 'text.primary' }}>
      We&apos;ll send a report to these email addresses every time we encounter a security
      event. Reports contain links to related signals, anomalies, and identity.
    </Typography>
    {children}
  </Stack>
);

const WebhookSection = ({ children }) => (
  <Stack spacing={2}>
    <Typography variant="overline" sx={{ color: 'text.secondary' }}>
      Webhook URL
    </Typography>
    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
      HTTPS required. Example: https://website.com/dfend-events
    </Typography>
    <Typography variant="body2" sx={{ color: 'text.primary' }}>
      We&apos;ll send JSON data to this URL every time we encounter a security event. Data
      includes findings and recommendations, IPs, locations, and templates with embedded
      links for user verification.
    </Typography>
    {children}
  </Stack>
);

const EventTypesSection = ({ children }) => (
  <Stack spacing={2}>
    <Typography variant="overline" sx={{ color: 'text.secondary' }}>
      Event types
    </Typography>
    <Typography variant="body2" sx={{ color: 'text.primary' }}>
      Configure the events that you receive. This is optional.
    </Typography>
    {children}
  </Stack>
);

const EventTypeSection = ({ type, children }) => (
  <Stack spacing={1} style={{ marginLeft: 12, paddingTop: 8 }}>
    <Typography variant="overline" sx={{ color: 'text.secondary' }}>
      {type.replaceAll('_', ' ')}
    </Typography>
    <Typography variant="body2" sx={{ color: 'text.primary' }}>
      {resultTypesDescriptions[type]}
    </Typography>
    {children}
  </Stack>
);

const DestinationsSection = ({ children }) => (
  <Stack spacing={3}>
    <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
      Destinations
    </Typography>

    {children}
  </Stack>
);

const SettingsSection = ({ children }) => (
  <Stack spacing={3}>
    <Box sx={{ pt: 1 }}>
      <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
        Settings
      </Typography>
    </Box>
    {children}
  </Stack>
);

const MAX_EMAILS = 5;

const RouteForm = ({ subscription, initValues = {}, id, accountId, isAdmin }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [emails, setEmails] = useState([
    ...Array(subscription?.destination?.emails?.length || 1)
  ]);
  const addEmail = () => {
    if (emails.length < MAX_EMAILS) {
      setEmails((current) => [...Array(++current.length)]);
    }
  };

  const rts = Object.keys(RESULT_TYPES).filter((key) => key in resultTypesDescriptions);

  const RouteSchema = object({
    email1: string().email('Email must be a valid email address'),
    email2: string().email('Email must be a valid email address'),
    email3: string().email('Email must be a valid email address'),
    email4: string().email('Email must be a valid email address'),
    email5: string().email('Email must be a valid email address'),
    webhookMethod: string()
      .matches(/GET|POST/)
      .optional(),
    webhookUrl: string()
      .url()
      .matches(/^(https:\/\/)?/)
      .optional(),
    config: object(
      rts.reduce(
        (acc, type) => ({
          ...acc,
          [type]: object({ enabled: bool() })
        }),
        {}
      )
    ).optional()
  });

  const _initValues = { ...initValues };
  delete _initValues.config;
  const initConfig = initValues.config;

  const defaultValues = {
    email1: '', //[],
    email2: '',
    email3: '',
    email4: '',
    email5: '',
    webhookMethod: 'POST',
    webhookUrl: '',
    config: {
      ...rts.reduce(
        (acc, type) => ({
          ...acc,
          [type]: { enabled: true }
        }),
        {}
      ),
      ...initConfig
    },
    ..._initValues
  };

  console.log('INIT VALUES:', _initValues);
  console.log('INIT CONFIG', initConfig);
  console.log('DEFAULT VALUES:', defaultValues);

  const methods = useForm({
    resolver: yupResolver(RouteSchema),
    defaultValues
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting, isDirty }
  } = methods;

  const onSubmit = async (data) => {
    console.log('submitting data:', data);
    if (!data.email1 && !data.webhookUrl) {
      enqueueSnackbar('Error updating routing. Must provide email, webhook, or both.', {
        variant: 'error'
      });
      return;
    }
    if (data.webhookUrl && !data.webhookMethod) {
      enqueueSnackbar('Error updating routing. Webhook must have an HTTP method.', {
        variant: 'error'
      });
      return;
    }
    const subscription = {
      destination: {
        emails: []
      }
    };
    [...Array(MAX_EMAILS)].forEach((e, i) => {
      if (data[`email${i + 1}`]) {
        subscription.destination.emails.push(data[`email${i + 1}`]);
      }
    });
    if (data.webhookUrl && data.webhookMethod) {
      subscription.destination.webhook = {
        url: data.webhookUrl,
        method: data.webhookMethod
      };
    }
    for (const type in data.config) {
      if (Object.hasOwnProperty.call(data.config, type)) {
        const val = data.config[type];
        // Don't clobber other types that might be in config.
        subscription[`config.${type}`] = val;
      }
    }
    try {
      console.log('SUBMIT CALLED:', subscription);
      if (id) {
        await updateSubscription(id, subscription);
      } else {
        subscription.account = accountId;
        await createSubscription(subscription);
      }
      enqueueSnackbar('Routing updated.');
    } catch (error) {
      enqueueSnackbar('Error updating routing.', {
        variant: 'error'
      });
      console.error(error);
    }
  };

  const resultTypes = {};
  rts.forEach((type) => (resultTypes[type] = watch(type)));

  return (
    <Card sx={{ p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={5}>
          <DestinationsSection>
            <Stack spacing={4}>
              <EmailSection>
                {emails.map((e, i) => (
                  <RHFTextField
                    key={i}
                    // style={{ marginTop: 24 }}
                    name={`email${i + 1}`}
                    label="Email address"
                    disabled={!isAdmin}
                  />
                ))}
                {emails.length < MAX_EMAILS ? (
                  <Button
                    size="small"
                    color="inherit"
                    onClick={addEmail}
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                    style={{ width: 110 }}
                  >
                    ADD EMAIL
                  </Button>
                ) : null}
              </EmailSection>

              <WebhookSection>
                <RHFTextField name="webhookUrl" label="Webhook URL" disabled={!isAdmin} />

                <RHFSelect
                  name="webhookMethod"
                  label="HTTP method"
                  disabled={!isAdmin}
                  selectProps={{
                    native: false,
                    MenuProps
                  }}
                >
                  {['GET', 'POST'].map((type) => (
                    <MenuItem key={type} value={type}>
                      <ListItemText primary={type} />
                    </MenuItem>
                  ))}
                </RHFSelect>
              </WebhookSection>
            </Stack>
          </DestinationsSection>

          <SettingsSection>
            <EventTypesSection>
              {rts.map((type) => (
                <EventTypeSection key={type} type={type}>
                  <RHFCheckbox
                    name={`config.${type}.enabled`}
                    label="Enabled"
                    //style={{ position: 'relative', top: -10 }}
                  />
                </EventTypeSection>
              ))}
              {/* <RHFSelect
                name="resultTypes"
                label="Types of events"
                disabled={!isAdmin}
                selectProps={{
                  native: false,
                  multiple: true,
                  MenuProps,
                  renderValue: (selected) => selected.join(', ')
                }}
              >
                {Object.keys(RESULT_TYPES).map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={resultTypes.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </RHFSelect> */}
            </EventTypesSection>
          </SettingsSection>

          <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ pt: 1 }}>
            {isDirty && (
              <Button color="inherit" size="small" onClick={() => reset(defaultValues)}>
                Discard changes
              </Button>
            )}

            <LoadingButton
              color="primary"
              size="small"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!isDirty}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </FormProvider>
    </Card>
  );
};

export default function Routing() {
  const { themeStretch } = useSettings();
  const { subscriptions, account, user } = useAuth();
  const [loadingVerifications, setLoadingVerifications] = useState(false);

  const subscription = subscriptions[0];
  const initValues = {};

  console.log('Subscription:', subscription);

  if (subscription && Object.keys(subscription).length) {
    const emails = subscription.destination?.emails;
    const webhook = subscription.destination?.webhook;
    const config = subscription.config;

    emails.forEach((email, i) => {
      initValues[`email${i + 1}`] = email;
    });
    if (webhook) {
      initValues.webhookMethod = webhook.method || 'POST';
      initValues.webhookUrl = webhook.url || '';
    }
    if (config) {
      const resultTypes = {};
      Object.keys(config).forEach((key) => {
        // Only result types.
        if (RESULT_TYPES[key]) {
          resultTypes[key] = config[key];
        }
      });
      initValues.config = resultTypes;
    }
  }

  return (
    <Page title={`Dashboard | Setup | ${title}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom>
            {title}{' '}
            {user.role !== 'admin' && (
              <Label color="error" sx={{ ml: 1 }}>
                <Iconify icon={'eva:alert-circle-fill'} />
                &nbsp;&nbsp;Must have admin role to make changes
              </Label>
            )}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 3 }}>
          <Card sx={{ p: 3 }}>
            <VerificationSection>
              <Box sx={{ flexDirection: 'row' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loadingVerifications}
                      checked={
                        account?.settings?.verification?.userInvolvement === 'dfend'
                      }
                      onClick={async (event) => {
                        console.log('checked', event.target.checked);
                        setLoadingVerifications(true);
                        await setUserInvolvement({
                          accountId: account.id,
                          useDfend: event.target.checked
                        });
                        setLoadingVerifications(false);
                      }}
                    />
                  }
                  label={
                    loadingVerifications
                      ? 'Updating...'
                      : 'Allow DFend to reach out to users about verifications.'
                  }
                  sx={{ flexGrow: 1, m: 0 }}
                />
              </Box>
            </VerificationSection>
          </Card>
        </Box>
        <Box sx={{ mt: 3 }}>
          {!!subscription?.id && (
            <RouteForm
              subscription={subscription}
              initValues={initValues}
              id={subscription?.id}
              accountId={account.id}
              isAdmin={user.role === 'admin'}
            />
          )}
        </Box>
      </Container>
    </Page>
  );
}
