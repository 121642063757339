import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';
// components
import ThemeSettings from './components/settings';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';

import { testQuery } from '../functions/query';
// export const q = () => {
//   console.log('hi');
//   testQuery();
// };
// ----------------------------------------------------------------------

const App = () => (
  <MotionLazyContainer>
    <ThemeProvider>
      <ThemeSettings>
        <NotistackProvider>
          <ProgressBarStyle />
          <ChartStyle />
          <ScrollToTop />
          <Router />
        </NotistackProvider>
      </ThemeSettings>
    </ThemeProvider>
  </MotionLazyContainer>
);

export default App;
