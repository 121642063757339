/* eslint-disable no-unused-vars */
// hm doesn't seem to work.

import { sortBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';

import Iconify from '...components/Iconify';
import Label from '...components/Label';
import Page from '...components/Page';
import Scrollbar from '...components/Scrollbar';
import { FormProvider, RHFSelect, RHFTextField } from '...components/hook-form';
import { TableHeadCustom } from '...components/table';
import { ROLE_TYPES } from '...data/members/constants';
import { roleSchema } from '...data/members/schemas/role';
import useAuth from '...hooks/useAuth';
import useSettings from '...hooks/useSettings';
import getHttpsCallable from '...utils/getHttpsCallable';
import { log } from 'console';

// import { useTheme } from '@mui/material/styles';

const addAccountMember = getHttpsCallable('addAccountMember');
const updateAccountMember = getHttpsCallable('updateAccountMember');
const removeAccountMember = getHttpsCallable('removeAccountMember');

const NewUserForm = ({ cancel }) => {
  const { account } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = object({
    displayName: string(),
    email: string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    role: roleSchema.required()
  });

  const defaultValues = {
    displayName: '',
    email: '',
    role: 'admin'
  };

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async (data) => {
    try {
      console.log('SUBMIT CALLED:', data);
      await addAccountMember({ ...data, accountId: account.id });
      enqueueSnackbar('User added.');
      reset();
    } catch (error) {
      enqueueSnackbar('Error adding user.', {
        variant: 'error'
      });
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 1 }}>
        <RHFTextField name="displayName" label="Full name" />
        <RHFTextField name="email" label="Email address" />
        <RHFSelect name="role" label="Role">
          {roles.map((role) => (
            <option key={role} value={role}>
              {role}
            </option>
          ))}
        </RHFSelect>
        <>
          <LoadingButton
            color="inherit"
            size="small"
            type="submit"
            loading={isSubmitting}
          >
            Add
          </LoadingButton>{' '}
          <Button color="inherit" size="small" onClick={cancel}>
            Cancel
          </Button>
        </>
      </Stack>
    </FormProvider>
  );
};

const roles = Object.values(ROLE_TYPES);

const MemberRow = ({ member, edit, remove, isEditing }) => (
  <TableRow>
    <TableCell>
      <Typography variant="subtitle2">
        {member?.user?.displayName}{' '}
        {member.owner && (
          <Label color="info" sx={{ ml: 1 }}>
            Owner
          </Label>
        )}
      </Typography>
    </TableCell>
    <TableCell sx={{ width: 200 }}>
      {isEditing ? (
        <RHFSelect name="role" label="Role">
          {roles.map((role) => (
            <option key={role} value={role}>
              {role}
            </option>
          ))}
        </RHFSelect>
      ) : (
        <Typography>{member.role}</Typography>
      )}
    </TableCell>
    <TableCell sx={{ width: 200 }}>
      {/* <IconButtonAnimate onClick={edit}>
          <Iconify icon={'eva:search-fill'} width={20} height={20} />
        </IconButtonAnimate> */}
      {edit}
    </TableCell>
    <TableCell sx={{ width: 200 }}>{remove}</TableCell>
  </TableRow>
);

// Members table
// ---------------------------------------------
const MembersTable = (props) => {
  const [editMember, setEditMember] = useState(null);
  const { members, user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isRemovingId, setIsRemovingId] = useState(null);

  /**
   * need more info
   * NEED userID
   */
  console.log('members', members);

  const UpdateRoleSchema = object({
    role: roleSchema.required()
  });

  const methods = useForm({
    resolver: yupResolver(UpdateRoleSchema)
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async ({ role }) => {
    try {
      const data = { memberId: editMember.id, role };
      console.log('SUBMIT CALLED:', data);
      await updateAccountMember(data);
      enqueueSnackbar('User updated.');
      setEditMember(null);
    } catch (error) {
      enqueueSnackbar('Error updating user.', {
        variant: 'error'
      });
      console.error(error);
    }
  };

  const getEdit = (member) => {
    if (member.owner || member.user.uid === user.id) return;
    if (editMember?.id !== member.id) {
      const edit = () => {
        setValue('role', member.role);
        setEditMember(member);
      };
      return (
        <Button color="inherit" size="small" onClick={edit}>
          Edit
        </Button>
      );
    } else {
      const cancel = () => {
        setEditMember(null);
      };
      return (
        <>
          <LoadingButton
            color="inherit"
            size="small"
            type="submit"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>{' '}
          <Button color="inherit" size="small" onClick={cancel}>
            Cancel
          </Button>
        </>
      );
    }
  };

  const getRemove = (member) => (
    <LoadingButton
      color="inherit"
      size="small"
      loading={isRemovingId === member.id}
      onClick={async () => {
        setIsRemovingId(member.id);
        try {
          await removeAccountMember({ uid: member.user.uid, accountId: member.entity });
          enqueueSnackbar('User removed from this workspace.');
        } catch (e) {
          enqueueSnackbar('Error removing user from this workspace.', {
            variant: 'error'
          });
        }
        setIsRemovingId(null);
      }}
    >
      Remove
    </LoadingButton>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card {...props}>
        {/* <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} /> */}
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720, p: 2 }}>
            <Table>
              <TableHeadCustom
                headLabel={[
                  // { id: 'member', label: 'member' },
                  // { id: 'debug', label: 'debug' },
                  { id: 'name', label: 'Name' },
                  { id: 'role', label: 'Role' },
                  { id: 'edit', label: '' },
                  { id: 'remove', label: '' }
                ]}
              />
              <TableBody>
                {members.map((member) => (
                  <MemberRow
                    key={member.id}
                    member={member}
                    debug={member.id}
                    edit={getEdit(member)}
                    remove={getRemove(member)}
                    isEditing={editMember?.id === member.id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </FormProvider>
  );
};

// Manage team page
// ---------------------------------------------
const title = 'Users';
const ManageTeam = () => {
  const { themeStretch } = useSettings();

  const [showAddUser, setShowAddUser] = useState(false);

  const toggleShowAddUser = () => {
    setShowAddUser(!showAddUser);
  };

  const newUserButton = (
    <Button
      size="large"
      //color="success"
      variant="contained"
      startIcon={<Iconify icon={'eva:plus-fill'} />}
      onClick={toggleShowAddUser}
      sx={{ whiteSpace: 'nowrap' }}
    >
      Add User
    </Button>
  );

  return (
    <Page title={`Dashboard | Manage | ${title}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 3, textAlign: 'right' }}>{newUserButton}</Box>
        {showAddUser && <NewUserForm cancel={toggleShowAddUser} />}
        <Box sx={{ mt: 3 }}>
          <MembersTable />
        </Box>
      </Container>
    </Page>
  );
};

export default ManageTeam;
