import { getDatabase, onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';

import useAuth from '...hooks/useAuth';
import { app } from '...modules/firebase/web';

const database = getDatabase(app);

const useCounts = (type, [year, month, day], total) => {
  const { account } = useAuth();
  const [counts, setCounts] = useState(total ? 0 : {});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!account?.id || !type) return;
    let datePath = '';
    if (year) {
      datePath += `/${year}`;
      if (month) {
        datePath += `/${month}`;
        if (day) {
          datePath += `/${day}`;
        }
      }
    }
    if (total) {
      datePath += '/total';
    }

    const path = `counts/${type}/${account.id}${datePath}`;

    console.log('watching rtdb path:', path);
    const dbRef = ref(database, path);

    return onValue(dbRef, (snapshot) => {
      const val = snapshot.val();
      console.log('rtdb data', val);
      setLoading(false);
      if (val) {
        setCounts(val);
      }
    }, e => console.error(e));
  }, [account?.id, type, year, month, day, total]);

  return [loading, counts];
}

export default useCounts;
