import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Map from 'react-map-gl';

import { Typography } from '@mui/material';
// @mui
import { styled, useTheme } from '@mui/material/styles';

import normalizeDate from '../../../core/utils/normalizeDate';
// components
import Iconify from '../../components/Iconify';
import { MapControl, MapMarker, MapPopup } from '../../components/map';
// config
import { MAPBOX_API } from '../../config';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  zIndex: 0,
  height: 560,
  overflow: 'hidden',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  '& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right': {
    display: 'none'
  }
}));

// ----------------------------------------------------------------------

ContactMap.propTypes = {
  contacts: PropTypes.array
};

export default function ContactMap({ contacts, initialViewState }) {
  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';

  const [popupInfo, setPopupInfo] = useState(null);

  return (
    <RootStyle>
      <Map
        initialViewState={
          initialViewState || {
            latitude: 39.0902,
            longitude: -95.7129,
            zoom: 2
          }
        }
        mapStyle={`mapbox://styles/mapbox/${isLight ? 'light' : 'dark'}-v10`}
        mapboxAccessToken={MAPBOX_API}
      >
        <MapControl hideGeolocateControl />

        {contacts.map((data, index) => (
          <MapMarker
            key={`marker-${index}`}
            latitude={data.latlng[0]}
            longitude={data.latlng[1]}
            anomalies={data.anomalies}
            onClick={(event) => {
              event.originalEvent.stopPropagation();
              setPopupInfo(data);
            }}
          />
        ))}

        {popupInfo && (
          <MapPopup
            longitude={popupInfo.latlng[1]}
            latitude={popupInfo.latlng[0]}
            onClose={() => setPopupInfo(null)}
            sx={{
              '& .mapboxgl-popup-content': { bgcolor: theme.palette.background.default },
              '&.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip': {
                borderTopColor: theme.palette.background.default
              },
              '&.mapboxgl-popup-anchor-top .mapboxgl-popup-tip': {
                borderBottomColor: theme.palette.background.default
              }
            }}
          >
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              {popupInfo.source}
            </Typography>

            <Typography component="p" variant="caption">
              {popupInfo.type}
            </Typography>

            <Typography component="p" variant="caption">
              {moment(normalizeDate(popupInfo.time)).format('YY-M-D h:mm:ssa')}
            </Typography>

            <Typography component="p" variant="caption">
              Anomalies: {popupInfo.anomalies}
            </Typography>

            {/* <Typography component="p" variant="caption" sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
              <Iconify icon={'eva:phone-fill'} sx={{ mr: 0.5, width: 14, height: 14 }} />
              {popupInfo.phoneNumber}
            </Typography> */}
          </MapPopup>
        )}
      </Map>
    </RootStyle>
  );
}
