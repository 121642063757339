// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { account } = useAuth();

  return (
    <Avatar
      src={account?.logoUrl}
      alt={account?.name}
      color={account?.logoUrl ? 'default' : createAvatar(account?.name).color}
      {...other}
    >
      {createAvatar(account?.name).name}
    </Avatar>
  );
}
