import { sentenceCase } from 'change-case';
import { uniqBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
// @mui
import { useTheme } from '@mui/material/styles';

import Iconify from '../../../components/Iconify';
// components
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import { TableHeadCustom, TableMoreMenu } from '../../../components/table';
// utils
import { fCurrency } from '../../../utils/formatNumber';
import normalizeDate from '...utils/normalizeDate';

// ----------------------------------------------------------------------

SignalsList.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  tableData: PropTypes.array.isRequired,
  tableLabels: PropTypes.array.isRequired
};

export default function SignalsList({
  title,
  subheader,
  tableData,
  tableLabels,
  canLoadMore,
  onLoadMore,
  ...other
}) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData.map((row) => (
                <SignalsListRow key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2 }}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Box>
            <Typography variant="body2">{subheader}</Typography>
          </Box>
          <Box>
            {canLoadMore ? (
              <Button
                size="small"
                color="inherit"
                endIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} />}
                onClick={onLoadMore}
              >
                Load More
              </Button>
            ) : null}
          </Box>
        </Stack>
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

SignalsListRow.propTypes = {
  row: PropTypes.shape({
    category: PropTypes.string,
    id: PropTypes.string,
    price: PropTypes.number,
    status: PropTypes.string
  })
};

function SignalsListRow({ row }) {
  const theme = useTheme();

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleDownload = () => {
    handleCloseMenu();
    console.log('DOWNLOAD', row.id);
  };

  const handlePrint = () => {
    handleCloseMenu();
    console.log('PRINT', row.id);
  };

  const handleShare = () => {
    handleCloseMenu();
    console.log('SHARE', row.id);
  };

  const handleDelete = () => {
    handleCloseMenu();
    console.log('DELETE', row.id);
  };

  const ip = row.data?.ip || '';
  const identity = row.identity || '';
  const anomalies = Array.isArray(row.immediateResults)
    ? uniqBy(row.immediateResults, 'type')
    : [];
  // if (row.immediateResults.length) console.log(row.immediateResults);
  return (
    <TableRow>
      <TableCell>
        <Link to={`/dashboard/insights/signals/${row.id}`}>
          <IconButton>
            <Iconify icon={'eva:external-link-fill'} sx={{ width: 18, height: 18 }} />
          </IconButton>
        </Link>
      </TableCell>

      <TableCell>
        {moment(normalizeDate(row.data.time)).format('YY-M-D h:mm:ssa')}
      </TableCell>

      <TableCell>{row.data?.source}</TableCell>

      <TableCell>{row.data?.type}</TableCell>

      <TableCell>
        {identity && (
          <Link to={`/dashboard/insights/identities/${identity}`}>
            <IconButton>
              <Iconify icon={'eva:external-link-fill'} sx={{ width: 18, height: 18 }} />
            </IconButton>
          </Link>
        )}
      </TableCell>

      <TableCell>
        {ip.slice(0, 16) || <Typography sx={{ color: 'text.secondary' }}>-</Typography>}
        {ip.length > 16 ? '...' : ''}
      </TableCell>

      <TableCell>{row.data?.enrichment?.ipdata?.emoji_flag}</TableCell>

      <TableCell>
        <Tooltip
          title={anomalies.map((anomaly) => anomaly.type).join(', ')}
          placement="left"
        >
          <div>
            <Label
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color={(anomalies.length && 'error') || 'success'}
            >
              {anomalies.length || 0}
            </Label>
          </div>
        </Tooltip>
      </TableCell>

      {/* <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem onClick={handleDownload}>
                <Iconify icon={'eva:download-fill'} />
                Download
              </MenuItem>

              <MenuItem onClick={handlePrint}>
                <Iconify icon={'eva:printer-fill'} />
                Print
              </MenuItem>

              <MenuItem onClick={handleShare}>
                <Iconify icon={'eva:share-fill'} />
                Share
              </MenuItem>

              <Divider sx={{ borderStyle: 'dashed' }} />

              <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
            </>
          }
        />
      </TableCell> */}
    </TableRow>
  );
}
