// @mui
import PropTypes from 'prop-types';

import { Button, Card, Stack, Typography } from '@mui/material';

// utils


// ----------------------------------------------------------------------

IntegrationCard.propTypes = {
  title: PropTypes.string,
  currentBalance: PropTypes.number,
  sentAmount: PropTypes.number,
  sx: PropTypes.any
};

export default function IntegrationCard({ title, label = 'Integration', cta, onClick, disabled, integration, sx, ...other }) {
  const isActive = integration?.status === 'active';

  return (
    <Card sx={{ p: 3, ...sx }} {...other}>
      <Typography variant="subtitle2" gutterBottom>
        {label}
      </Typography>

      <Stack spacing={2}>
        <Typography variant="h3">{title}</Typography>

        <Stack direction="row" spacing={1.5}>
          {isActive ? (<Button fullWidth variant="contained" color="success">
            Active
          </Button>) : (
            <Button fullWidth variant="contained" color="primary" onClick={onClick} disabled={disabled}>
              {cta}
            </Button>
          )}

          {/* <Button fullWidth variant="contained">
            Receive
          </Button> */}
        </Stack>
      </Stack>
    </Card>
  );
}
