import { Button, Container, Grid, Stack, Typography } from '@mui/material';
// @mui
import { useTheme } from '@mui/material/styles';

// _mock_
import { _appAuthors, _appInstalled, _appInvoices, _appRelated } from '../../_mock';
// assets
import { SeoIllustration } from '../../assets';
// components
import Page from '../../components/Page';
// hooks
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
// sections
import {
  AppAreaInstalled,
  AppCurrentDownload,
  AppNewInvoice,
  AppTopAuthors,
  AppTopInstalledCountries,
  AppTopRelated,
  AppWelcome,
  AppWidget,
  AppWidgetSummary
} from '../../sections/@dashboard/general/app';

// ----------------------------------------------------------------------

export default function GeneralApp() {
  const { user } = useAuth();

  const theme = useTheme();

  const { themeStretch } = useSettings();

  return (
    <Page title="General: App">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={12}>
            <AppWelcome
              // title={`Welcome back! \n ${user?.displayName}`}
              title="Welcome back!"
              description="Get started with DFend in minutes."
              img={
                <SeoIllustration
                  sx={{
                    p: 3,
                    width: 360,
                    margin: { xs: 'auto', md: 'inherit' }
                  }}
                />
              }
              action={
                <>
                  <Button variant="contained">Connect your app, website, or Google Workspace</Button>
                </>
              }
            />
          </Grid> */}

          {/* <Grid item xs={12} md={4}>
            <AppFeatured list={_appFeatured} />
          </Grid> */}

          <Grid item xs={12} md={12}>
            <Typography variant="h4">Recent</Typography>
            <Typography variant="subtitle2">Last 7 Days</Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Signals"
              percent={2.6}
              total={18765}
              chartColor={theme.palette.primary.main}
              chartData={[5, 18, 12, 51, 68, 11, 39, 37, 27, 20]}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Alerts"
              percent={0.2}
              total={4876}
              chartColor={theme.palette.chart.blue[0]}
              chartData={[20, 41, 63, 33, 28, 35, 50, 46, 11, 26]}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Identities"
              percent={-0.1}
              total={678}
              chartColor={theme.palette.chart.red[0]}
              chartData={[8, 9, 31, 8, 16, 37, 8, 33, 46, 31]}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppCurrentDownload
              title="Sources"
              chartColors={[
                theme.palette.primary.lighter,
                theme.palette.primary.light,
                theme.palette.primary.main,
                theme.palette.primary.dark
              ]}
              chartData={[
                { label: 'Mac', value: 12244 },
                { label: 'Window', value: 53345 },
                { label: 'iOS', value: 44313 },
                { label: 'Android', value: 78343 }
              ]}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppAreaInstalled
              title="Activity"
              subheader="(+43%) than last year"
              chartLabels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']}
              chartData={[
                {
                  year: '2019',
                  data: [
                    { name: 'Signals', data: [10, 41, 35, 51, 49, 62, 69, 91, 148] },
                    { name: 'Anomalies', data: [10, 34, 13, 56, 77, 88, 99, 77, 45] }
                  ]
                },
                {
                  year: '2020',
                  data: [
                    { name: 'Signals', data: [148, 91, 69, 62, 49, 51, 35, 41, 10] },
                    { name: 'Anomalies', data: [45, 77, 99, 88, 77, 56, 13, 34, 10] }
                  ]
                }
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} lg={8}>
            <AppNewInvoice
              title="Signals"
              tableData={_appInvoices}
              tableLabels={[
                { id: 'id', label: 'Signal ID' },
                { id: 'category', label: 'IP' },
                { id: 'price', label: 'Location' },
                { id: 'status', label: 'Anomalies' },
                { id: '' }
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTopRelated title="Top Related Applications" list={_appRelated} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTopInstalledCountries title="Top Installed Countries" list={_appInstalled} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTopAuthors title="Top Authors" list={_appAuthors} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Stack spacing={3}>
              <AppWidget title="Conversion" total={38566} icon={'eva:person-fill'} chartData={48} />
              <AppWidget
                title="Applications"
                total={55566}
                icon={'eva:email-fill'}
                color="warning"
                chartData={75}
              />
            </Stack>
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
