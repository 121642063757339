import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup
} from 'firebase/auth';

import { onRegister, onSignIn } from '../hooks';
import { app } from '...modules/firebase/web';
import getHttpsCallable from '...utils/getHttpsCallable';

const auth = getAuth(app);
const authRegisterUser = getHttpsCallable('authRegisterUser');

const provider = new GoogleAuthProvider();

// TODO: Support sign in with redirect on mobile: signInWithRedirect(auth, provider);
// From docs:
// You can prompt your users to sign in with their Google Accounts either by opening a pop-up window
// or by redirecting to the sign-in page. The redirect method is preferred on mobile devices.

export const register = async ({ displayName, company }) => {
  const userCredential = await signInWithPopup(auth, provider);
  // To support these hooks on signInWithRedirect, we would need to move them to FirebaseContext.
  const result = await authRegisterUser({
    displayName,
    company
  });
  console.log('authRegisterUser response', result.data);
  console.log('id token updated...force refreshing token');
  onRegister('google', userCredential);
  return userCredential;
};

export const signIn = async () => {
  const userCredential = await signInWithPopup(auth, provider);
  onSignIn('google', userCredential);
  return userCredential;
};
