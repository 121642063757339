import { onSnapshot, query, setDoc, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
// @mui
import { useTheme } from '@mui/material/styles';

// _mock_
// import { _appInvoices } from '../../_mock';
import Page from '../../components/Page';
import useAuth from '../../hooks/useAuth';
import useCounts from '../../hooks/useCounts';
import useSettings from '../../hooks/useSettings';
import AppNewInvoice from '../../sections/@dashboard/insights/Identities';
import identities from '...data/identities/collections/identities';
import useIdentities from '...hooks/useIdentities';

// ----------------------------------------------------------------------

const title = 'Identities';

export default function Identities() {
  const [loadingCount, count] = useCounts('identities', [], true);
  const [page, setPage] = useState(1);
  const [loadingIdentities, identities] = useIdentities(page);
  // const { user } = useAuth();

  // const theme = useTheme();

  const { themeStretch } = useSettings();

  const displaying = `Displaying ${count === 0 ? 0 : 1} - ${
    identities.length
  } of ${count}`;

  return (
    <Page title={`Dashboard | Insights | ${title}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h4">{title}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            {identities && identities.length ? (
              <AppNewInvoice
                title="Identities"
                subheader={displaying}
                tableData={identities}
                tableLabels={[
                  { id: 'id', label: 'Link' },
                  { id: 'name', label: 'Name' },
                  { id: 'email', label: 'Email' },
                  { id: 'phone', label: 'Phone' },
                  { id: 'optedIn', label: 'Opted In' },
                  { id: 'date', label: 'Created' }
                  // { id: 'status', label: 'Anomalies' },
                  // { id: '' }
                ]}
                canLoadMore={identities.length < count}
                onLoadMore={() => !loadingIdentities && setPage(page + 1)}
              />
            ) : loadingIdentities ? (
              <Typography>Loading...</Typography>
            ) : (
              <Typography>No identities received yet.</Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
