import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { APPROVALS_PATH } from '...data/approvals/constants';
import { app } from '...modules/firebase/web';

const firestore = getFirestore(app);

const getApproval = async (id) => {
  const docRef = doc(firestore, APPROVALS_PATH, id);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : undefined;
};

export default function useResult(id) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) return;

    (async () => {
      setLoading(true);
      const _doc = await getApproval(id);
      setData(_doc);
      setLoading(false);
    })();
  }, [id]);

  console.log('APPROVAL', data);

  return [loading, data];
}
