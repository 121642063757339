// @mui
import { Button, Stack, Typography } from '@mui/material';

// assets
// import { DocIllustration } from '../../../assets';
// hooks
import useAuth from '../../../hooks/useAuth';
// import useLocales from '../../../hooks/useLocales';
// routes
import { PATH_DOCS } from '../../../routes/paths';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { user } = useAuth();

  // const { translate } = useLocales();

  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
    >
      {/* <DocIllustration sx={{ width: 1 }} /> */}

      <div>
        <Typography gutterBottom variant="subtitle1">
          {/* translate('docs.hi') */}Hi, {user?.displayName}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}
        >
          {/* translate('docs.description') */}Need help?
        </Typography>
      </div>

      <Button href={PATH_DOCS} target="_blank" rel="noopener" variant="contained">
        {/* translate('docs.documentation') */}Please check our docs.
      </Button>
    </Stack>
  );
}
