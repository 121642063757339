// TODO: Use this? https://www.npmjs.com/package/@stripe/firestore-stripe-payments
import { collection, getFirestore, query, where } from 'firebase/firestore';

import { app } from '...modules/firebase/web';

const firestore = getFirestore(app);

// export default collection(firestore, 'members');

export const getSubscriptionsQuery = (currentUserId: string) => {
  const subscriptions = collection(firestore, `stripe_customers/${currentUserId}/subscriptions`);
  return query(subscriptions, where('status', 'in', ['trialing', 'active']));
};

export const getProductsQuery = () => {
  const products = collection(firestore, 'stripe_products');
  return query(products, where('active', '==', true));
};

export const getPricesQuery = (productId: string) => query(collection(firestore, `stripe_products/${productId}/prices`));

export const getCheckoutSessions = (currentUserId: string) =>
  collection(firestore, `stripe_customers/${currentUserId}/checkout_sessions`);

