import { Box, Container, Divider, Typography } from '@mui/material';

import Markdown from '../../components/Markdown';
import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';
import content from './SetupSdk.md';

export default function ApiKeys() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Dashboard | Setup | SDK">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom>
            SDK
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 3 }}>
          <Markdown>{content}</Markdown>
        </Box>
      </Container>
    </Page>
  );
}
