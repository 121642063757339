import { node } from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';
import Login from '../pages/auth/Login';
import Billing from '../pages/dashboard/Billing';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, account } = useAuth();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (isAuthenticated && !account) {
    return <LoadingScreen />;
  }

  // if (isAuthenticated && account && account?.billing?.subscription?.status !== 'active') {
  //   return <Billing />;
  // }

  return <>{children}</>;
}
