import { onSnapshot, orderBy, query, setDoc, where } from 'firebase/firestore';
import { isFinite, uniqBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';
import { ContactMap } from '../../sections/contact';
import Iconify from '...components/Iconify';
import Label from '...components/Label';
import useIdentity from '...hooks/useIdentity';

// import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const title = 'Identity';

const SectionContainer = ({ title, children }) => (
  <Stack spacing={2} sx={{ mt: 4, mb: 4 }}>
    {!!title && (
      <div>
        <Typography variant="h6" sx={{ color: 'text.primary' }}>
          {title}
        </Typography>
      </div>
    )}
    {children}
  </Stack>
);

const Section = ({ title, caption, body, children }) => (
  <Stack spacing={1}>
    {!!title && (
      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {title}
      </Typography>
    )}
    {!!caption && (
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        {caption}
      </Typography>
    )}
    {!!body && (
      <Typography variant="body2" sx={{ color: 'text.primary' }}>
        {body}
      </Typography>
    )}
    {children}
  </Stack>
);

const dateFormat = 'YYYY/MM/DD [at] h:mm:ss a';
const getDate = (d) => moment(d?.toDate()).format(dateFormat);

export default function InsightsIdentity() {
  let { id } = useParams();

  // const { user } = useAuth();

  const theme = useTheme();

  const { themeStretch } = useSettings();

  const [loading, i] = useIdentity(id);

  console.log('Identity?', id, i);

  // ---------------------------------------------

  // const none = (
  //   <>
  //     <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>NONE</Label>
  //   </>
  // );

  const none = '--';

  // ---------------------------------------------

  const created = getDate(i?._m?.c);

  // Identity.
  const ide = i?.identifiers;
  const names = ide?.names?.join(', ');
  const emails = ide?.emails?.join(', ');
  const phones = ide?.phones?.join(', ');
  const userIds = ide?.userIds?.join(', ');

  // TODO: Right now when we store locations, we don't store the coordinates of those locations.
  // That's alright but it means we need to pull signals for this identity to show anything
  // on a map.

  // ---------------------------------------------

  return (
    <Page title={`Dashboard | Insights | ${title}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container sx={{ mb: 2 }}>
          <Grid item /*xs={r?.level !== 'INFO' ? 8 : 12}*/>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          {/* {r?.level !== 'INFO' && (
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
                //pr: 1.5
              }}
            >
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color="error"
              >
                {r?.level === 'CRITICAL' ? 'HIGH RISK' : 'MEDIUM RISK'}
              </Label>
            </Grid>
          )} */}
        </Grid>
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <div>
              <Typography variant="h5" style={{ display: 'inline' }}>
                Risk Score
              </Typography>
              <Typography style={{ display: 'inline' }}>Current</Typography>
            </div>
            <Divider />
          </Grid> */}
          <Grid item xs={12} md={12}>
            <Divider />
            <SectionContainer>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Section title="Created" body={created} />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Section title="Source" body={source} />
                </Grid> */}
                {/* <Grid item xs={12} md={6}>
                  <Section title="IP" body={ip} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Section title="Company" body={companyName} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Section title="Recognized VPN" body={`${isVpn}`} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Section title="User Agent" body={`${userAgent || 'none'}`} />
                </Grid> */}
                {/* <Grid item xs={12} md={6}>
                  <Section title="Trusted" body={`${trusted}`} />
                </Grid> */}
              </Grid>
            </SectionContainer>
            <Divider />
            <SectionContainer title="Identifiers">
              {!!names && <Section title="Names" body={names} />}
              {!!emails && <Section title="Emails" body={`${emails}`} />}
              {!!phones && <Section title="Phone Numbers" body={`${phones}`} />}
              {!!userIds && <Section title="User IDs" body={`${userIds}`} />}
            </SectionContainer>
            <Divider />
            <SectionContainer title="Devices">
              {i?.devices?.length ? (
                i.devices.map(
                  (
                    {
                      model,
                      osName,
                      osVersion,
                      deviceId,
                      serialNumber,
                      installId,
                      identifierForVendor,
                      androidId,
                      imei,
                      approved,
                      rejected
                    },
                    index
                  ) => (
                    <div key={index}>
                      <Stack spacing={3} sx={{ mb: i?.devices?.length > 1 ? 2 : 0 }}>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Section title="Model" body={model || none} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Section
                              title="OS"
                              body={
                                `${osName || ''}${osVersion ? ` / ${osVersion}` : ''}` ||
                                none
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Section title="Device ID" body={deviceId || none} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Section title="IMEI" body={imei || none} />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Section title="Serial Number" body={serialNumber || none} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Section title="Install ID" body={installId || none} />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Section
                              title="iOS Vendor ID"
                              body={identifierForVendor || none}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Section title="Android ID" body={androidId || none} />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Section title="Approved" body={`${!!approved}`} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Section title="Rejected" body={`${!!rejected}`} />
                          </Grid>
                        </Grid>
                      </Stack>
                      {i?.devices?.length > 1 && index !== i?.devices?.length - 1 && (
                        <Divider />
                      )}
                    </div>
                  )
                )
              ) : (
                <Grid container>
                  <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>
                    NONE
                  </Label>
                </Grid>
              )}
            </SectionContainer>
            <Divider />
            <SectionContainer title="Locations">
              {i?.locations?.length ? (
                i.locations.map(
                  (
                    { city, region, regionType, country, emojiFlag, approved, rejected },
                    index
                  ) => (
                    <div key={index}>
                      <Stack spacing={3} sx={{ mb: i?.locations?.length > 1 ? 2 : 0 }}>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Section title="City" body={city} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Section title={regionType} body={region} />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12} md={12}>
                            <Section title="Country" body={`${country} ${emojiFlag}`} />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Section title="Approved" body={`${!!approved}`} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Section title="Rejected" body={`${!!rejected}`} />
                          </Grid>
                        </Grid>
                      </Stack>
                      {i?.locations?.length > 1 && index !== i?.locations?.length - 1 && (
                        <Divider />
                      )}
                    </div>
                  )
                )
              ) : (
                <Grid container>
                  <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>
                    NONE
                  </Label>
                </Grid>
              )}
            </SectionContainer>
            <Divider />
            <SectionContainer title="IPs">
              {i?.ips?.length ? (
                i.ips.map(({ ip, approved, rejected }, index) => (
                  <div key={index}>
                    <Stack spacing={3} sx={{ mb: i?.ips?.length > 1 ? 2 : 0 }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Section title="IP" body={ip || none} />
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Section title="Approved" body={`${!!approved}`} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Section title="Rejected" body={`${!!rejected}`} />
                        </Grid>
                      </Grid>
                    </Stack>
                    {i?.ips?.length > 1 && index !== i?.ips?.length - 1 && <Divider />}
                  </div>
                ))
              ) : (
                <Grid container>
                  <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>
                    NONE
                  </Label>
                </Grid>
              )}
            </SectionContainer>
            <Divider />
            <SectionContainer title="VPNs">
              {i?.vpns?.length ? (
                i.vpns.map(({ provider, approved, rejected }, index) => (
                  <div key={index}>
                    <Stack spacing={3} sx={{ mb: i?.vpns?.length > 1 ? 2 : 0 }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Section title="Provider" body={provider || none} />
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Section title="Approved" body={`${!!approved}`} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Section title="Rejected" body={`${!!rejected}`} />
                        </Grid>
                      </Grid>
                    </Stack>
                    {i?.vpns?.length > 1 && index !== i?.vpns?.length - 1 && <Divider />}
                  </div>
                ))
              ) : (
                <Grid container>
                  <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>
                    NONE
                  </Label>
                </Grid>
              )}
            </SectionContainer>
            <Divider />
            <SectionContainer title="Carriers">
              {i?.carriers?.length ? (
                i.carriers.map(({ name, mcc, mnc, approved, rejected }, index) => (
                  <div key={index}>
                    <Stack spacing={3} sx={{ mb: i?.carriers?.length > 1 ? 2 : 0 }}>
                      <Grid container>
                        <Grid item xs={6} md={12}>
                          <Section title="Name" body={name || none} />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={6} md={6}>
                          <Section title="Mobile Country Code" body={mcc || none} />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Section title="Mobile Network Code" body={mnc || none} />
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Section title="Approved" body={`${!!approved}`} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Section title="Rejected" body={`${!!rejected}`} />
                        </Grid>
                      </Grid>
                    </Stack>
                    {i?.carriers?.length > 1 && index !== i?.carriers?.length - 1 && (
                      <Divider />
                    )}
                  </div>
                ))
              ) : (
                <Grid container>
                  <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>
                    NONE
                  </Label>
                </Grid>
              )}
            </SectionContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <Box>
              {!!r && (
                <ContactMap
                  contacts={coords}
                  initialViewState={
                    coords.length
                      ? {
                          latitude: coords[0].latlng[0],
                          longitude: coords[0].latlng[1],
                          zoom: 7
                        }
                      : null
                  }
                />
              )}
            </Box> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
